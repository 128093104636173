import './hero.css'
import suits from '../../assets/suits.jpg'
import suits1 from '../../assets/suits1.jpg'
import suits3 from '../../assets/suits/blezer.jpg'

import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom'
import { getState, getUser } from '../../Firebase/auth'

export default function Hero(){

    const navigate = useNavigate()

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const caps = ["Custom Tailored", "Perfect Fit", "High Quality Material", "Fast Delivery"]
    const [active1, setActive1] = useState(0)
    // useEffect(() => {
    //     setTimeout(
    //     () =>
    //         setActive1((prevIndex) =>
    //             prevIndex === caps.length - 1 ? 0 : prevIndex + 1
    //         ),
    //     2000
    //     );

    //     return () => {};
    // }, [active1]);

    const data = [{image:suits1, caption:"1", description:"first"},
                    {image:suits3, caption:"2", description:"second"},
                    {image:suits, caption:"3", description:"Third"} ]

    return(
        <div className="Hero">
            
            <div className='hero-image-container'>
                    <Carousel activeIndex={index} onSelect={handleSelect} fade>
                        {data.map((slide, i) => {
                            return (
                            <Carousel.Item>
                            <img
                            className="hero-img"
                            src={slide.image}
                            alt="slider image"
                            />
                            
                        </Carousel.Item>
                            )
                        })}
                </Carousel>

            </div>

            <div
                className='patti'
            >
                {/* <div style={{"position":"relative", "width":"100%"}}> */}
                <label style={{"width":"fit-content"}} className='verti_center'>
                        {caps[active1]}
                </label>
                {/* </div> */}
            </div>

            <div className='hero-full'></div>  
            <div className='hero-txt'>   
                <label className='title-font hero_title mob-title'>
                Elevate Your Style <br/>
                with Tailored Elegance
                </label><br/>

                <div className='hero-des txt-font mob-text'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>

                <div className='hero-buttons'>
                    <button className='Butt2 txt-font1 '
                        onClick={()=>{
                            navigate("/custom")
                        }}
                    >
                        <span>Customize</span>
                    </button>
                    
                    <button className='Butt1 txt-font1'
                        onClick={()=>{
                            // console.log(getState())
                            // console.log(getUser())
                            // navigate("/custom")
                        }}
                    > 
                        <span>Explore</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
