import React, { useState, useEffect } from 'react';
import './App.css';
import phone from './assets/phone.svg';
import cart from './assets/cart.svg';
import acc from './assets/acc.svg';
import Logo from './assets/Logo.png';
import Logo1 from './assets/download.svg';

import { useNavigate } from 'react-router-dom';
import { logout, getState } from './Firebase/auth';
import MobileNavBar from './MobileNavbar';

export default function NavBar({ flag = 1 }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const accountClick = () => {
    if (getState()) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  return (
    <div
      className="NavBar"
      style={
        flag === 0 ? { background: 'transparent' } : flag === 2 ? { position: 'fixed' } : {}
      }
    >
      <div className="Navbody txt-font">
        {isMobile ? (
          // Mobile View
          <MobileNavBar />
        ) : (
          // Desktop View
          <div className="nav-desktop">
            <div className="nav-left txt-font1">
              <div onClick={() => navigate('/')} type="button">
                Home
              </div>
              <div onClick={() => navigate('/')} type="button">
                About Us
              </div>
              <div onClick={() => navigate('/custom')} type="button">
                Customize
              </div>
            </div>

            <div>
              <img src={Logo1} alt="Logo" className='logo_main'/>
            </div>

            <div className="nav-right txt-font1">
              <img src={phone} className="nav-logos" alt="Phone" />
              <img
                src={cart} className="nav-logos" alt="Cart"
                onClick={() => navigate('/test')}
              />
              <img
                src={acc} className="nav-logos" alt="Account"
                onClick={accountClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
