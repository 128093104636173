import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getState } from "../Firebase/auth"; 
import { AuthContext } from "../Firebase/AuthProvider";

function Cost({change, page, setPage}) {
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const [data, setData] = useState( JSON.parse(localStorage.getItem("sel")))
    const [price, setPrice] = useState();
    useEffect(()=>{
        let Data = JSON.parse(localStorage.getItem("sel"))
        setData(Data)
        let p = 0;
        let fab = 0;
        if(Data){for(let i=0;i<Data.length;i++){
            if(Data[i].price){
                p+=Number(Data[i].price)
            }else{
                fab=  fab+1
            }
            
        }
        if(fab>1){
            p+=250
        }else{
            p+=100
        }

        setPrice([{"typ":"Your Items total", "value":getNumebr(p)},
                {"typ":"GST (12%)", "value": getNumebr(0.12*Number(p))},
                {"typ":"Shipping", "value":getNumebr(0)},
                {"typ":"Total", "value":getNumebr(p+0.12*Number(p))}])}
    }, [change])

    const getNumebr=(p)=>{
        return(
            p.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            })
        )

    }

    return ( 
    <div className="cost"   
    >   
        { data && data.length > 0 && <>
        <div className="cost-des">
            <div style={{"width":"100%", "textAlign":"center"}}>
                <div className="hurray">Hurray !! your first Suit Order</div>
                <div className="free" style={{"color":"green"}}>You got free shipping</div>
            </div>
            <br/>
            <div className="txt-font1 subt">Subtotal {data.length}</div>
            
            {price && price.map((val, key)=>{
                return( 
                    <div className={key === price.length-1 ? "cost-row1 txt-font1 colorr" : "cost-row"} >
                        <div className="c-left">{val["typ"]}</div>
                        <div className="c-right">{val["value"]}</div>
                    </div>
                )
            })}

            {/* <div className="cost-row1 txt-font1">
                <div className="c-left">Total</div>
                <div className="c-right">$7000</div>
            </div> */}
        </div>

        {page == 0 && <button className="Butt1 hori_center checkout-butt"
            style={{"position":"relative", "marginTop":"20px"}}
            onClick={()=>{
                setPage(1)
                // const currOrder =  JSON.parse(localStorage.getItem("sel"))
                // localStorage.setItem("sel", JSON.stringify({...currOrder, ...{"breakUp":price}}))

                if(user){

                }else{
                    navigate("/login", {state:{from:"test"}})
                }
                
            }}

        >
            Checkout
        </button>}
        </> }
    </div> 

    );
}

export default Cost;