import './presets_main.css'

import P1 from '../assets/suits/product-1.jpg'
import P2 from '../assets/suits/uniform.jpg'
import P3 from '../assets/suits/pant_shirt.jpg'
import {PreFilter} from '../Data'

import mbg from '../assets/mbg1.jpg'
import Foot from '../footer'
import { useNavigate } from 'react-router-dom'
function Presets_main() {

    const navigate = useNavigate()
    const presets = [
        {
            name:"Wedding Tux", src:P1, price:11999,
            fabric:"Cotton",brand:"Raymond", type:0
        },
        {name:"Women Formals", src:P2,price:11999,
        fabric:"Cotton",brand:"Raymond", type:0},
        {name:"Blue Suede", src:P3, price:10999,
        fabric:"Cotton",brand:"Raymond", type:0},
        {name:"Wedding Tux", src:P1, price:9999,
        fabric:"Cotton",brand:"Raymond", type:0},
        {name:"Women Formals", src:P2, price:8999,
        fabric:"Cotton",brand:"Raymond", type:0},
        {name:"Blue Suede", src:P3, price:7999,
        fabric:"Cotton",brand:"Raymond", type:0},
        {name:"Wedding Tux", src:P1, price:6999,
        fabric:"Cotton",brand:"Raymond", type:0},
        ];

    return ( 
    <div className="presets_main">
        <div className="Hero1">
            
            <div className='hero-image-container1'>
                <img src={mbg} className='pre-hero-img'/>
            </div>

            <div className='hero-full1'></div>  
            <div className='hero-txt1 hori_center'>   
                <label className='title-font hero_title'>
                Elevate Your Style <br/>
                with Tailored Elegance
                </label><br/>

                <div className='hero-des1 txt-font'>
                    Hand crafted by our Proffesionals
                </div>

            </div>
        </div>

        <div className='p-sec'>
            <div className='pre-left'>
                <div className='pre-filter hori_center'>
                    {PreFilter.map((val, key)=>{
                        return(<>
                            <div className='txt-font'>{val["name"]}</div><br/>
                            {val["items"].map((v, k)=>{
                                return(
                                    <div class="form-check pre-f-ele">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label class="form-check-label txt-font1" for="flexCheckDefault">
                                        {v}
                                    </label>
                                    </div>
                                )
                            })}
                        </>)

                    })}
                </div>

            </div>
            <div className='pre-right'>
            <div class="row">
                    {presets && presets.map((value, key)=>{
                        return (
                            <div class="col-sm-3 pre-card" type="button">
                                <div class="P-card3"
                                    style={{backgroundImage:`url(${value["src"]})`}}
                                    onClick={()=>{
                                        navigate('/test3', {state:{"sel":value}})
                                    }}
                                >
                                

                                </div>
                                <div class="pre-card-body">
                                        <div className="txt-font1">
                                            {value.name} <br/> 
                                            <label className="txt-font1" >
                                                {value.price.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</label>
                                        </div>
                                    </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
        <div>
            <Foot/>
        </div>
    </div> );
}

export default Presets_main;