import { useContext, useEffect, useState } from "react";
import Cart from "./cart";
import './checkout.css'
import Cost from "./cost_details";
import Address from "./address";
import { db } from "../firebase";

import tick from '../assets/icon/tick.png'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Firebase/AuthProvider";



function Checkout() {

    const { user } = useContext(AuthContext);

    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const [change, setChange] = useState(0)

    const [checkoutData, setcheck] = useState({})

    const [payment, paymentStatus] = useState({"id":-100});

    const [order,setOrder] = useState()

    const senEmail = async (params)=>{
        
        const headers = {
            "Content-Type" : "application/json"
        }
        const resp = await fetch('https://suits-backend-jey7.vercel.app/sendMail', {method : 'POST',headers:headers,body:JSON.stringify(params)})
        const res = await resp.json()

        return res.status === 0 ? true : false


    }

    const postOrder = async()=>{

        try{
            let orders = JSON.parse(localStorage.getItem('sel'))
            if(orders){ 
                // // console.log("Checking order details",order)
                for(let i = 0;i<orders.length;i+=1){
                    orders[i]["payment_order_id"] = order["razorpay_order_id"]
                    orders[i]["payment_id"] = order["razorpay_payment_id"]
                    orders[i]["Address"] = checkoutData["address"]
                }
                // console.log("dumping in DB", orders)
                
                const collection = db.collection('Orders')
                var batch = db.batch();
                // console.log("passing this")
                const email = user.email
                const curr = new Date()

                const OrderID = curr.getDate().toString()+"-"+curr.getTime().toString()
                var i = 0
                orders.forEach((doc) => {
                    i = i+1
                    const ID = (new Date().getTime()).toString()
                    // console.log("ID from orders, ", ID)
                    const finalOrder = {...doc, ...{"Date":Date(), "id":ID, "email":email, "orderId":OrderID}}
                    batch.set(collection.doc(ID), finalOrder);
                })
                const result = await batch.commit()
                
                paymentStatus({...payment, "id":1})
                localStorage.removeItem('sel')

                const pramsData = {
                    "email" : email,
                    "name" : user.displayName,
                    "orderId" :  OrderID ,
                    "items" : i.toString(),
                    "price" : checkoutData["price"]
                }
                console.log("User Detaisl", pramsData)
                senEmail(pramsData).then(()=>{})
                // console.log("postOrder", result)
            }else{
                // console.log("No orders")
                paymentStatus({...payment, "id":-1})
                // return error
            }
        } catch(e){
            // console.log("Error",e)
            paymentStatus({...payment, "id":-1})
        }
    }

    useEffect(()=>{
        
        if(payment["id"] === 0 && payment["status"] === true){
            postOrder().then(()=>{})
        }else if(payment["id"] === 0 && payment["status"] === false){
            paymentStatus({
                "id": -1
            })
        }

    }, [payment])

    useEffect(()=>{
        let Data = JSON.parse(localStorage.getItem("sel"))
        let p = 0;
        let fab = 0;
        if(Data){

            for(let i=0;i<Data.length;i++){
                if(Data[i].price){
                    p+=Number(Data[i].price)
                }else{
                    fab+=1
                }
                
            }
            if(fab>1){
                p+=250
            }else{
                p+=100
            }

        }
        setcheck({"price":p+0.12*Number(p)})


    }, [])

    return ( 
    <div className="Checkout">

        {page === 0 && payment["id"] === -100 ? (
            <div className="cart-page">
                <Cart
                    setChange = {setChange}/>
                <Cost
                    change = {change}
                    setChange = {setChange}

                    page = {page}
                    setPage= {setPage}
                />
            </div>
        ) 
        : payment["id"] === -100 ? (<div className="cart-page">
            <Address
                data={checkoutData}
                setData  ={setcheck}
                setPage ={setPage}

                payment = {payment}
                paymentStatus = {paymentStatus}
                setOrder = {setOrder}
            />
            <Cost
                change = {change}
                setChange = {setChange}
            />
    </div>) : (
        <div className="payment_confirm">
            {payment["id"] === 0 ? (
                <div className="payment_process both_center">
                    <img alt src="https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.svg" onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                    <h2>Please Wait</h2>
                    <h2>Processing your order</h2>       
                </div>) 
            : payment["id"] === 1 ? (
                <div className="payment_process both_center">
                 <div>   <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                </div>
                    <h2>Payment Success</h2>
                    <h2>Order Received</h2>
                    <br/>
                    <button className="Butt1" 
                        onClick={()=>{
                            navigate("/profile")
                        }}
                    >View Order</button>
                </div>) 
            : payment["id"]===-1 ? (<div><br/><br/><br/>Payment Failed</div>) : (
            <div className="payment_process both_center">
                    <img alt src="https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.svg" onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                    <h2>Please Wait</h2>
                    <h2>Processing your order</h2>       
                </div>
            )

            }
        </div>
        // <h1> <br/><br/><br/>{payment === 0 ? `Payment Processing - ${payment}. Please wait ...` : payment === 1 ? `Payment Success ${payment}` : `Payment Failed ${payment}`} </h1>
    )}
        
    </div> );
}

export default Checkout;