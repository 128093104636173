import { useEffect, useState } from 'react';
import suit from '../assets/samples/s1.png'

import minus from '../assets/icon/minus.png'
import plus from '../assets/icon/plus1.png'
import Foot from '../footer';
import { useLocation, useNavigate } from 'react-router-dom';

function Preset_item() {

    const navigate = useNavigate()
    const [Data , setData] = useState()
    const location = useLocation()
    useEffect(()=>{
        if(location && location.state && location.state["sel"]){
            setData(location.state["sel"])
        }
    })
    const [QTY, setQty] = useState(1)

    const handleClick = (val)=>{
        if(val === 0){
            if(QTY != 0){
                setQty(QTY - 1)    
            }
        }else{
            setQty(QTY + 1)
        }
    }
    return ( 
    <div className="pre-item">

        <div className="pre-sec-wrap">
            <div className="pre-item-left">
                
                <img src={suit} className="pre-item-img"/>
            </div>
            <div className="pre-item-right">
                <div className="pre-item-des-sec">
                    <div className="title-font">
                        {Data ? Data["name"] :"Example Suite" }
                    </div>
                    <div className="txt-font">
                        {Data ? `${'$'}${Data["price"]}` :"Example Suite" }
                    </div>
                    <div className="pre-item-des">
                        I'm a product description. I'm a great place to add more details about your product such as sizing, material, care instructions and cleaning instructions.
                    </div>
                    <div
                        className='hori_center'
                        style={{"display":"flex", "gap":"10px"}}
                    >
                    <div className='quantity'>
                        <div className='quantity_buts'>
                            <img src={minus} className='qty_butts' type="button"
                                style={QTY === 0 ? {"opacity":"0.2"}:{}}
                                onClick={()=>{
                                    handleClick(0)
                                }}
                            />
                        </div>
                        <div className='qty_des txt-font1'>{QTY}</div>
                        <div className='quantity_buts'>
                            <img src={plus} className='qty_butts' type="button"
                                onClick={()=>{
                                    handleClick(1)
                                }}
                            />
                        </div>
                    </div>
                    <button className="Butt1 add-cart"
                        onClick={()=>{
                            
                            const curr = JSON.parse(localStorage.getItem("sel"))

                            var feel_car = [ {...Data, ...{item_type:1}}
                            ]
                            
                            if(curr && curr.length>0){
                                localStorage.setItem("sel", JSON.stringify([...curr, ...feel_car]))
                            }else{
                                localStorage.setItem("sel", JSON.stringify([...feel_car]))
                            }
                            
                            navigate("/test")
                        }}
                    >Add to Cart</button>
                    </div>
                    <div className="txt-font">Product Info</div>
                    <div className="pre-product-des">
                        I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions. This is also a great space to write what makes this product special and how your customers can benefit from this item.
                    </div>

                </div>
            </div>
        </div>
        <div>
            <Foot/>
        </div>
    </div> );
}

export default Preset_item;