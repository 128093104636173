import { useRef } from 'react';
import './contact.css'
import contact from '../assets/contact/contact.jpg'
function Appointment() {

    const email = useRef()
    const name = useRef()
    const phone = useRef()
    const org = useRef()
    const des = useRef()
    const n = useRef()
    return ( 
    <div className="Appointment"
        style={{"background":`url(${contact})`, "backgroundRepeat":"no-repeat", "backgroundSize":"cover"}}
    >
        <div className='a-form both_center'>
        <div className=' txt-font'>
            <label className='hori_center' style={{"width":"fit-content"}} >
                <b>Order Request</b>
            </label>
        </div>
        <form>
            <div class="form-group">
                <label for="exampleInputEmail1"><b>Email address</b></label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div className='row_in'>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Name</b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Name"/>
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Organization</b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Organization"/>
            </div>
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Description</b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Description"/>
            </div>
            <div className='row_in'>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Contact Number</b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Contact Number"/>
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1"><b>Number of orders (estimated)</b></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Number of Orders"/>
            </div>
            </div>
            {/* <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div> */}
            <button type="submit" class="Butt1 hori_center" ><span>Submit</span></button>
            <div style={{"fontSize":"0.8rem"}}> <span style={{"color":"red"}}>*</span>contact us only if you have minimum order request of 100 </div>
            </form>

        </div>
    </div> );
}

export default Appointment;