
import tick from '../../assets/icon/tick1.png'

function Feel_left({Fabric,selection ,setSelection, hoverState, sHover}) {
    return (
        <div className="fab_left">
            <div className="hori_center" style={{"width":"90%", "fontWeight":"bold"}}>Select Fabric</div>
            <div
                style={{"width":"90%", "fontSize":"0.7rem"}} className="hori_center"
            >Experience the Fabric before you order. You can select fabrics and get a sample delivered to your home.
                Select Maximum 5 fabrics. Select 1 Fabric $100. Select two or more   </div>
            
            <div className="feel_fabric_wrapper hori_center">
            <div className="feel_fabric_list">
                {Fabric && Fabric.map((value, key)=>{

                    return(
                        <div style={{"position":"relative"}}>
                            <div style={{ "zIndex":"10" ,"position":"absolute", "bottom":"0", "height":"18px", "background":"transparent", "width":"100%"}}
                                        type="button"
                                        onClick={()=>{
                                            hoverState(1)
                                            
                                            sHover({title:value.fabric, brand:value.brand, price:value.price, fabric:value.src, "q":value.id})
                                            hoverState(-1)
                                            
                                        }}
                                    >

                            </div>
                        <div class="card1"
                            type="button"
                            style={{"backgroundImage":`url(${value["src"]})`}}
                            onClick={()=>{
                                const ID = value.id;
                                const checkID = obj => obj.id === ID;

                                if(selection.length < 5 || selection.some(checkID)){

                                    if(selection.some(checkID)){

                                        var temp = []
                                        for(let i=0;i<selection.length;i++){
                                            if(selection[i].id != ID){
                                                temp.push(selection[i])
                                            }
                                        }
                                        console.log("selecting again")
                                        setSelection(temp)
                                        

                                    } else{
                                        setSelection([...selection, ...[value]])
                                    }
                                } else {

                                }
                                
                            }}
                        >
                            <div class="card-body1"
                                
                            >
                                <div className="s-f-price txt-small1"> 
                                    { selection.some(obj => obj.id === value.id) && <img src = {tick} style={{"width":"20px"}}/> }
                                </div>
                                <div className="s-f-foot">
                                    {value.name} | <label className="txt-small1" >{value.brand}</label>
                                </div>
                            </div>
                        </div>
                        <div style={{"position":"relative"}} className="s-f-i-c-bottom"
                                        
                        >
                            <div style={{"width":"fit-content", "zIndex":"10"}} className="both_center"
                                type="button"
                                
                            >
                                
                                    {/* <img src={} className="sel-info"
                                        style={isMobile ? {"width":"18px"}:{}}
                                    /> */}
                                    <b><label>Fabric info</label></b>
                                
                            </div>
                            {/* <b><label className="fab_inf hori_center">
                            {!isMobile ? <img src={info} className="sel-info"/> :<></>}
                                Fabric info</label></b> */}
                        </div>
                        </div>
                    )
                })

                }

            </div>
            </div>
        </div>
      );
}

export default Feel_left;