import { useEffect, useState } from "react";
import Feel_left from "./feel_left";
import { db } from "../../firebase";
import { getData, getDocOnCondition } from "../../Firebase/firestoreGet";
import '../fabric.css'
import './feel_fab.css'
import Fabric_modal from "./fabric_modal";
import { useNavigate } from "react-router-dom";

function Feel_fabric() {

    const navigate = useNavigate()

    const [Fabric, setFabric] = useState()

    const [selection, setSelection] = useState()
    const [hoverDetils, setHoverDets] = useState({})
    const [sHover, setHover] = useState(-2)

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

    

    const getFabricDetails1 = async(id)=>{

        // /Fabric/Suits/fabrics
        const coll = db.collection("Fabric").doc("Suits").collection("fabrics")
        const data  = await getDocOnCondition(coll,"id", id )
        // // // // console.log("From function :", id, data)
        return data[0]
    }

    useEffect(()=>{
        const collection = db.collection('Fabric').doc('Suits').collection('fabrics')
        
        getData(collection).then((data)=>{
            setFabric(data)
            setSelection([data[0]])
        })
    }, [])

    useEffect(()=>{
        
        if( hoverDetils && Object.hasOwn(hoverDetils, "q")){
            
            getFabricDetails1(hoverDetils["q"]).then((data)=>{  
                // console.log("From inside promise :", data)
                var Data = {...data}
                var s = ""
                Data.color.forEach((ele)=>{
                    if(ele.length > 0){
                        s = s + ele
                    }
                })
                Data["color"] = s
                setHoverDets(Data)
            }).catch((e)=>{
                // console.log("from useEffect", e)
            })
        }
    }, [hoverDetils])

    const addToCart = ()=>{
        
        const curr = JSON.parse(localStorage.getItem("sel"))

        var feel_car = []
        
        selection.forEach((value)=>{
            feel_car.push({...{...value}, ...{item_type:0}})
        })
        
        if(curr && curr.length>0){
            localStorage.setItem("sel", JSON.stringify([...curr, ...feel_car]))
        }else{
            localStorage.setItem("sel", JSON.stringify([...feel_car]))
        }
        
        navigate("/test")
    }

    return ( 
    <div className="feel_fabric">

        { (sHover == -1) && hoverDetils && <Fabric_modal
            hoverDetils = {hoverDetils}
            setHoverDets = {setHoverDets}
            setHover = {setHover}
        />}

        <Feel_left
            Fabric={Fabric}
            selection={selection}
            setSelection = {setSelection}
            sHover={setHoverDets}
            hoverState={setHover}

        />
        <div className="feel_right1">
            <div className="f-r-top">
                <div className="txt-font"> Not sure on the selection of Fabric ? </div>
                <div> Experience the Fabric before you order. You can select fabrics and get a sample delivered to your home. Select Maximum 5 fabrics. Select 1 Fabric $100. Select two or more at $250 </div>
            </div>

            <div className="" style={{"width":"fit-content", "marginTop":"20px"}}>
                <div className="txt-font1"><b> Selected ({!selection ? 0 : selection.length}/5)</b></div>
                <div style={{"width":"fit-content", "display":"flex", "gap":"10px"}}>
                    {selection && selection.slice(0, 3).map((val, key)=>{
                        return(
                            <div className="fab_sel">
                                <img src={val.src}/>
                                <div> <b>{val.name}</b> | <span>{val.brand}</span> </div>
                            </div>
                        )
                    })}
                </div>
                <div style={{"width":"fit-content", "display":"flex", "gap":"10px"}} className="hori_center">
                    {selection && selection.slice(3).map((val, key)=>{
                        return(
                            <div className="fab_sel">
                                <img src={val.src}/>
                                <div> <b>{val.name}</b> | <span>{val.brand}</span> </div>
                            </div>
                        )
                    })}
                </div>

            </div>

            <div className="fab_check">
                <div className="txt-font1">Fabrics Selected : {selection && selection.length}</div>
                <div style={{"display":"flex", "gap": "30px"}}>
                <div><label className="verti_center txt-font"> Total {selection && selection.length>1 ? 250 : 100 } </label></div>
                <button className="Butt1" 
                    onClick={()=>{
                        addToCart()
                    }}
                ><span>Add to Cart</span></button>
                </div>
            </div>
            
        </div>
    </div> 
    );
}

export default Feel_fabric;