import { Suspense, useEffect, useState, useRef } from "react";

import close from '../../assets/icon/close.png'

// for selection of custom clothing
// import blazer from '../../assets/icon/blazer.svg'
// import trouser from '../../assets/icon/trousers.svg'
// import vest from '../../assets/icon/vest.svg'
// import Shirt from '../../assets/icon/shirt.svg'

import blazer from '../../assets/icon/SVG2/coat.svg'
import trouser from '../../assets/icon/SVG2/pant.svg'
import vest from '../../assets/icon/SVG2/vest.svg'
import Shirt from '../../assets/icon/SVG2/shirt.svg'

import NavImg from '../../assets/icon/edit.jpg'
import plus from '../../assets/icon/plus-thin.svg'
import tut from '../../assets/tut.png'
import info from '../../assets/icon/info1.png'
//    

import rarrow from '../../assets/icon/Rarrow.png'
import larrow from '../../assets/icon/Larrow.png'
import Switch from '@mui/material/Switch';

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';

import bg from '../../assets/samples/bg.jpg'
import search_svg from '../../assets/icon/social/search.png'

import { getData } from "../../Firebase/firestoreGet";

import {Types} from '../../Data'
import { db } from "../../firebase";
import { Fab } from "@mui/material";
// const Types = ["Coat", "Shirt", "Pant", "Vest"]
const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function SelectFabric({Fabric,useFabric, setUseFabric, sel, setSel, sHover, hoverState, nav, setNav, hover,setFabricsAll, setFabricTypes, FabricTypes,menuOpen2,setMenuOpen2, menuOpen, clickHover, setClickHover, getStyleImg, setStylesAll,imgState ,setImgState, fabSame, setFabSame, fabricType, setType}){
    
    const [modal, setModal] = useState(0);
    const [types, setTyp] = useState([1, 0, 1, 0])
    
    const [Ffilter, setFabricFilter] = useState(0)
    // const [fabricType, setType] = useState("")
    const [clothes, setClothes] = useState(Types)

    

    // useState(()=>{
    //     if(Fabric){
    //         setUseFabric(Fabric)
    //     }
        
    // }, [Fabric])

    const [search, setSearch] = useState(false)

    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };
    
    const sidebarRef2 = useRef(null);
    useEffect(() => {
        // Set the initial selected color to the first fabric image
        if (Fabric && Fabric[fabricType] && Fabric[fabricType][0]) {
          setSelectedColor(Fabric[fabricType][0].fabricImg);
        }
      }, [Fabric, fabricType]);
      
    const toggleDropdown = () => {
        // console.log("toggled")
        setShowDropdown((prev) => !prev);
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 480);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

    useEffect(()=>{
        if(FabricTypes){
            setType(FabricTypes[0])
        }
    }, [FabricTypes])

    // useEffect(()=>{
        
    //     if(FabricTypes){
    //     if(imgState <= 2){
    //         setType("Coat")
    //     }
    //     if(sel && sel.types && sel.types.includes("Shirt")){
    //         if(imgState === 3){
    //             setTyp("Shirt")
    //         }
    //         else if(imgState <= 5){
    //             setTyp("Pant")
    //         } else {
    //             setTyp("Vest")
    //         }

    //     } else {
    //         console.log("Coming to else", imgState)
    //         if(imgState === 3){
    //             setTyp("Pant")
    //             console.log("Changing to pant")
    //         }
    //         else {
    //             setTyp("Vest")
    //         }
    //     }
    //     }
        

    // }, [imgState])
    
    const select = (key, typ = null)=>{
        if(key === 1 || key === 3)
        {var a = [...types]
        a[key] = Number(!a[key])
        if(typ){a[key] = typ}
        // // console.log(a, typ)
        setTyp(a)}
    }
    const calPrice = (a)=>{  
        let p = 0
        let D = Number(0)
        Object.keys(a["fabric"]).forEach((element)=>{
            if(a["fabric"][element]["discount"]){
                const perc = Number(a["fabric"][element]["discount"])
                D = D + (Number(a["fabric"][element]["price"])*( perc/100 ) )
            }
            p+=Number(a["fabric"][element]["price"])
        }) 

        return [p, D]
    }
    const getDiscount = (a)=>{
        
    }
    

    // const ImageWithLoading = ({ src }) => (
    //     <LoadedState>
    //       {({ state: loaded, setState: setLoaded }) => (
    //         <div>
    //           {!loaded ? (
    //             <svg width="100" height="100" viewBox="0 0 100 100">  
    //               <rect width="100" height="100" rx="10" ry="10" fill="#CCC" />
    //             </svg>
    //           ) : null}
    //           <img
    //             src={src}
    //             style={!loaded ? { visibility: 'hidden' } : {}}
    //             onLoad={() => setLoaded(true)}
    //           />
    //         </div>
    //       )}
    //     </LoadedState>
    // );

    return(
        <div className="selectFabric"
            style={Ffilter == 1 ? {transform:"translateX(20vw)"} : {}}
        >
            {
                nav === -1 ? (
                <div className="sectionSectionFull"
                    style={isMobile && nav === -1 ? {"background":`url(${bg})`, "backgroundSize":"cover", "backgroundRepeat":"no-repeat", "backgroundPosition":"bottom"}:{}}
                >
                { isMobile && nav===-1 && <div style={{"position":"absolute", "width":"100%", "height":"100%", "background":"rgb(48,48, 48, 0.3)"}}>

                </div>}
                <div className="Selection-Body both_center">

                    <div className="Dress-up-mobile">
                    <div className="mobile-button">
                    {isMobile && (
                            <div className="proceed-button-mobile">
                              <button className="Butt2 both_center"
                        onClick={()=>{
                            var fabric = {}
                            
                            for(let i=0;i<types.length;i++){
                                if(types[i] === 1){
                                    
                                    fabric[Object.keys(Types)[i]] = {
                                        "brand":"" ,// "" Fabric[Object.keys(Types)[i]][0]["brand"], 
                                        "name":"" ,//Fabric[Object.keys(Types)[i]][0]["fabric"], 
                                        "price":"" ,//Fabric[Object.keys(Types)[i]][0]["price"],
                                        "src":"" ,//Fabric[Object.keys(Types)[i]][0]["src"],
                                        "pos":Types[Object.keys(Types)[i]]}
                                }
                            }
                            setFabricTypes(Object.keys(fabric))
                            setSel({
                                ...sel, 
                                "types":Object.keys(fabric),
                                "fabric":fabric
                            })
                            // console.log({
                            //     ...sel, 
                            //     "types":Object.keys(fabric),
                            //     "fabric":fabric
                            // })
                            setImgState(0)
                            setNav(0);
                            
                        }}
                    ><span></span>Proceed</button>  
                            </div>
                        )}
                    <div className="txt-font2">Dress Up</div>
                    </div>
                    <div className="txt-font3">Suit up by Choosing</div>
                    {
                      isMobile && (
                        <div className="mobile-only-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
  )
}
                    <div className="sel-card-sec"
                        
                    >
                        
                        <div className="sel-card mob_sel_card "
                            
                            onClick={()=>{     
                                select(0)
                            }}
                            style={types[0] === 1 ? {
                                "border":"1px solid black", "box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            } : {"box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}
                        >
                            <div className="sel-top">
                                <label>{Object.keys(Types)[0]}</label>
                                {/* <img src={close} type="button"
                                    onClick={()=>{
                                        select(0, 0)
                                    }}
                                /> */}
                            </div>
                            <div className="sel-card-image-container" style={{"width":"100%", "height":"7vw"}}>
                                {types[0] === 0 ? (<img src={plus} className="sel-card-img img-plus"/>)
                                : (<img src={blazer} className="sel-card-img"/>)}
                                
                            </div>
                            
                        </div>
                        <div className="sel-card mob_sel_card"
                            onClick={()=>{
                                select(1)
                            }}
                            style={types[1] === 1 ? {"border":"1px solid black", "box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"} : {
                                
                            }}
                        >
                            <div className="sel-top">
                                <label>{Object.keys(Types)[1]}</label>
                                <img src={close} type="button"
                                    onClick={()=>{
                                        select(1, 0)
                                    }}
                                />
                            </div>
                            
                            <div style={{"width":"100%", "height":"7vw"}}>
                                {types[1] === 0 ? (<img src={plus} className="sel-card-img img-plus"/>)
                                : (<img src={Shirt} className="sel-card-img"/>)}
                                
                            </div>
                        </div>
                    </div>
                    <div className="sel-card-sec">
                        <div className="sel-card mob_sel_card"
                            onClick={()=>{
                                select(2)
                            }}
                            style={types[2] === 1 ? {"border":"1px solid black", "box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"} : {}}
                        >
                            <div className="sel-top">
                                <label>{Object.keys(Types)[2]}</label>
                                {/* <img src={close} type="button"
                                    onClick={()=>{
                                        select(2, 0)
                                    }}
                                /> */}
                            </div>
                            
                            <div style={{"width":"100%", "height":"7vw"}}>
                                {types[2] === 0 ? (<img src={plus} className="sel-card-img img-plus"/>)
                                : (<img src={trouser} className="sel-card-img"/>)}
                                
                            </div>
                        </div>
                        <div className="sel-card mob_sel_card"
                            onClick={()=>{
                                select(3)
                            }}
                            style={types[3] === 1 ? {"border":"1px solid black", "box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"} : {}}
                        >
                            <div className="sel-top">
                                <label>{Object.keys(Types)[3]}</label>
                                <img src={close} type="button"
                                    onClick={()=>{
                                        select(3, 0)
                                    }}
                                />
                            </div>
                            
                            <div style={{"width":"100%", "height":"7vw"}}>
                                {types[3] === 0 ? (<img src={plus} className="sel-card-img img-plus"/>)
                                : (<img src={vest} className="sel-card-img"/>)}
                                
                            </div>
                            
                        </div>
                        
                    </div>
                    </div>
                    
                    

                </div>
                {!isMobile && (
                <div className="viewOpac"
                    style={{"background" : `url(${NavImg}) no-repeat`, "backgroundSize":"cover" 
                }}
                >
                    <div className="sel-ins">
                        {/* <img src={tut} className="tut"/> */}
                        <b>Step 1</b>
                        <div className="sel-ins-des">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </div>
                    </div>
                    <div className="sel-ins">
                        {/* <img src={tut} className="tut"/> */}
                        <b>Step 2</b>
                        <div className="sel-ins-des">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </div>
                    </div>
                    <div className="sel-ins">
                        {/* <img src={tut} className="tut"/> */}
                        <b>Step 3</b>
                        <div className="sel-ins-des">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </div>
                    </div>
                    
                    <button className="Butt1"
                        onClick={()=>{
                            var fabric = {}
                            console.log("TYPES :", types)
                            for(let i=0;i<types.length;i++){
                                if(types[i] === 1){
                                    console.log("From Types : ", Object.keys(Types)[i], Types[Object.keys(Types)[i]])
                                    fabric[Object.keys(Types)[i]] = {
                                        "brand":"" ,// "" Fabric[Object.keys(Types)[i]][0]["brand"], 
                                        "name":"" ,//Fabric[Object.keys(Types)[i]][0]["fabric"], 
                                        "price":"" ,//Fabric[Object.keys(Types)[i]][0]["price"],
                                        "src":"" ,//Fabric[Object.keys(Types)[i]][0]["src"],
                                        "pos":Types[Object.keys(Types)[i]]}
                                }
                            }
                            setFabricTypes(Object.keys(fabric))
                            console.log({
                                ...sel, 
                                "types":Object.keys(fabric),
                                "fabric":fabric
                            })
                            setSel({
                                ...sel, 
                                "types":Object.keys(fabric),
                                "fabric":fabric
                            })
                            // console.log({
                            //     ...sel, 
                            //     "types":Object.keys(fabric),
                            //     "fabric":fabric
                            // })
                            setImgState(0)
                            setNav(0);
                        }}
                    > <span>Proceed</span></button>
                </div>
                )}
                
                </div>)
                
                : (<>
                
                {(isMobile && menuOpen2) && <div className={isExpanded ? 'expand-button0' : 'expand-button1'}
                    onClick={toggleSidebar}
                > <img src={isExpanded ? larrow : rarrow} style={{"width":"20px"}} /> </div>}
            {/* background mask to deal with opacity and z-index positioning */}
            <div className={`selectFabric2 ${isMobile ? (menuOpen2 ? 'show' : 'hidden') : ''} ${isExpanded ? 'expanded' : ''} ` } ref={sidebarRef2} >
            
            <>
            
            
            {isMobile && (
                <div>
            {/* <button className="expand-button" onClick={toggleSidebar} > */}
                
            
        {/* </button> */}
            <span className="close-sidebar" onClick={() => setMenuOpen2(false)}>&times;</span>
            </div>
        )}
                    
                    </>
                    
                
            
                <div 
                    className="left-panel"
                // style={
                //     {
                //     position: "absolute",
                //     top: "0",
                //     height: "100%",
                //     width: "100%",
                //     // background: "rgba(20, 33, 61, 0.1)"
                // }}
                >
                    {/* Content of the div you want to conditionally remove */}
                </div>
            
            {/* selection of clothes */}

            <div className={`s-options-fabric hori_center ${isExpanded ? 'opt-100' : 'opt-50'}`}
                // style={(fabSame || (isMobile && !isExpanded)) ? {"display":"none"} : {}}
            >
            
            

            <div className={`s-extra1 s-extra2 ${isExpanded ? 'ext1-100' : 'ext1-50'} `}
                style={(fabSame || (isMobile && !isExpanded)) ? {"backgroundColor":"#14213D"}:{}}
            ></div>
            
            <div className={`s_fabric_type verti_center ${isExpanded ? 'type-100' : 'type-50'}`}
                style={ 
                    (fabSame || (isMobile && !isExpanded)) ? {} : sel["types"] && sel["types"].includes("Vest") ? {"width":"40%"} :{"width":"30%"}
                }
            >
                {(fabSame || (isMobile && !isExpanded)) ? (
                    <div className="s_f_t_c2 verti_center" style={{"backgroundColor":"#14213D", "color":"white"} }
                                                                onClick={()=>{setType("Coat")}}
                >
                    <label style={{"height":"fit-content"}} className="verti_center">{"Suit"}</label>
                </div>) 
                : (<>
                {sel["types"] && sel["types"].map((val, key)=>{
                    return(
                        <>
                        {val !== "Shirt" && 
                        <div className="s_f_t_c verti_center flex_grow"

                        style={val === fabricType ? {"backgroundColor":"#14213D", "color":"white"} : {}}
                            onClick={()=>{setType(val)}}
                        >{val}</div>
                        }
                        </>
                    )
                })}
                </>)}
                
            </div>
            <div className={`s-extra ${isExpanded ? 'ext-100' : 'ext-50'} `}
                style={(fabSame || (isMobile && !isExpanded)) ? {"backgroundColor":"#14213D"}:{}}
            ></div>

            
                <div
                        className="shirt-option"
                        style={ sel && sel.types && !sel.types.includes("Shirt") ? {"display":"none"}:{}}
                >
                
                <div className={`s-extra1 ${isExpanded ? 'ext1-100' : 'ext1-50'} `}
                    style={"Shirt" === fabricType ? {"backgroundColor":"#14213D", "color":"white"} : {}}
                ></div>
                <div className="s_f_t_c1 both_center"
                        style={"Shirt" === fabricType ? {"color":"white"} : {}}
                            onClick={()=>{setType("Shirt")}}
                >{"Shirt"}</div>
                <div className={`s-extra ${isExpanded ? 'ext-100' : 'ext-50'} `}
                    style={"Shirt" === fabricType ? {"backgroundColor":"#14213D", "color":"white"} : {}}
                ></div>
            </div>
            

                <div
                        className="verti_center"  
                        style={{"position":"absolute",  "right":"0","display":"flex", "width":"fit-content", "height":"40px"}}
                >
                
                <div className={`s-extra1 ${isExpanded ? 'ext1-100' : 'ext1-50'} `}
                    style={{"backgroundColor":"#14213D", "color":"white"}}
                ></div>
                <div className="s_f_t_c1 both_center"
                            type ="button"
                            style={{"color":"white"}}
                            onClick={()=>{setType(0);setNav(-1)}}
                >{"Edit"}</div>
                <div className={`s-extra ${isExpanded ? 'ext-100' : 'ext-50'} `}
                    style={{"backgroundColor":"#14213D", "color":"white"}}
                ></div>
                </div>
                

            </div>
            {/* { (fabSame || (isMobile && !isExpanded)) && <>
                <div
                className="hori_center suit_single "
                    style={isMobile ? {"top":"5vh"} : {"top":"10px"}}
                    
                >
        
                    <div className={`s-extra1`}
                        style={{"backgroundColor":"#14213D", "color":"white"}}
                    ></div>
                    <div className="s_f_t_c2"
                                style={{"color":"white"}}
                                onClick={()=>{
                                    if(!fabSame || (isMobile && !isExpanded)){
                                        setType(0);setNav(-1)
                                    }
                                    
                                }}
                    > <label
                    className="verti_center"
                        style={{"height":"fit-content"}}
                    >{ fabSame ? "Suit" : "Edit Options"}</label> </div>
                    <div className={`s-extra `}
                        style={{"backgroundColor":"#14213D", "color":"white"}}
                    ></div>
            </div>
            <div
                        className="shirt-option"
                        
                >
                
                <div className={`s-extra1 ${isExpanded ? 'ext1-100' : 'ext1-50'} `}
                    style={"Shirt" === fabricType ? {"backgroundColor":"#14213D", "color":"white"} : {}}
                ></div>
                <div className="s_f_t_c1 both_center"
                        style={"Shirt" === fabricType ? {"color":"white"} : {}}
                            onClick={()=>{setType("Shirt")}}
                >{"Shirt"}</div>
                <div className={`s-extra ${isExpanded ? 'ext-100' : 'ext-50'} `}
                    style={"Shirt" === fabricType ? {"backgroundColor":"#14213D", "color":"white"} : {}}
                ></div>
            </div>
            </>
            } */}

            <Tooltip TransitionComponent={Zoom} title="Enable this to make selection altogether. If you want to change any specific Fabric, pelase disable the button">
                    {/* <Button>Zoom</Button> */}
                    <div className="same_fabric"
                
                    ><b>Same Fabric</b> <Switch {...label} defaultChecked 
                        // onChange={(e)=>{console.log(e.target.value)}}
                        onChange={() => {
                            // console.log(!fabSame);
                            setFabSame(prevCheck => !prevCheck) ; }}
                        value={fabSame}
                        checked={!!fabSame}
                    /> </div>
            </Tooltip>
            

            <div className="selectbody txt-font">
                <div className="s-title">
                    {isMobile || (!isMobile && !search) ? 
                    <label  className={`txt-font ${isExpanded ? 'font-100' : 'font-50'}`}
                        onClick={
                            ()=>{
                                setSearch(true)
                            }
                        }
                    >
                        Select Fabric <img src={search_svg} style={{"width":"25px","opacity":"0.7"}} />
                    </label> :
                    <label style={{"position":"relative", "width":"70%", "height":"40px", "margin":"0", "fontSize":"1rem"}}>
                        <input placeholder="Search"
                            style={{"width":"100%", "border":"none", "height":"40px"}}
                        />
                        <img src={close} className="verti_center" style={{"position":"absolute", "right":"0", "zIndex":"2", "width":"25px"}}
                            onClick={()=>{
                                setSearch(false)
                            }}
                        />
                    </label>
                    }
                    
                    <button className="filterButt"
                        onClick={()=>{
                            setFabricFilter(!Ffilter)
                        }}
                    >Filters  </button>
                
                </div>

                {/* {fabricType} */}
                <div className="mob-main-2">
                    
                    <div className={`fabricList ${isExpanded ? 'sidebar-100' : 'sidebar-50'}`}>
                        {useFabric && useFabric[fabricType] && useFabric[fabricType].map((value, key)=>{
                            return (
                                <>
                                <div class="col-sm-6 mb-4" className="main-mon-2"
                                    // onMouseEnter={() => {
                                    //     sHover({...{title:value.fabric, brand:value.brand, price:value.price, fabric:value.fabricImg}})
                                    //     hoverState(key)
                                    // }}
                                    // onMouseLeave={()=>{
                                    //     hoverState(-2)
                                    // }}
                                >
                                    {( sel && sel["fabric"] && sel["fabric"][fabricType] && sel["fabric"][fabricType]["fabricID"] && sel["fabric"][fabricType]["fabricID"] === value["fabricID"]) &&
                                    <div style={{ "zIndex":"10" ,"position":"absolute", "bottom":"-18px", "height":"18px", "background":"transparent", "width":"100%"}}
                                        type="button"
                                        onClick={()=>{
                                            hoverState(1)
                                            
                                            sHover({title:value.fabric, brand:value.brand, price:value.price, fabric:value.fabricImg, "q":value.fabricID})
                                            // console.log("from hover", {title:value.fabric, brand:value.brand, price:value.price, fabric:value.fabricImg, "q":value.fabricID})
                                            hoverState(-1)
                                            
                                        }}
                                    >

                                    </div>}
                                    <div class="card1"
                                        type="button"
                                        style={{"backgroundImage":`url(${value["fabricImg"]})`}}
                                        onClick={()=>{
                                            
                                            if(fabSame && fabricType!== "Shirt"){
                                                var updatedSel = {...sel}
                                                
                                                sel.types.forEach((ele)=>{
                                                    if(ele !== "Shirt"){
                                                    updatedSel = {...updatedSel, 
                                                        ...{
                                                        "fabric":{
                                                        ...updatedSel["fabric"], 
                                                        ...{[ele]:{"fabric":value.fabric, "fabricImg":value.fabricImg, 
                                                        "brand":value.brand, "price": useFabric[ele][key].price, "src":useFabric[ele][key].src, "pos":Types[ele],
                                                        "single":useFabric[ele][key]["single"], "style":useFabric[ele][key]["style"], "id":useFabric[ele][key]["id"], "fabricID":value["fabricID"], "discount":useFabric[ele][key]["discount"], "brandImg" : value["brandImg"]
                                                    }}
                                                     }
                                                    }}}
                                                })
                                            }else {
                                            var updatedSel = {...sel,
                                                ...{
                                                   "fabric":{
                                                   ...sel["fabric"], 
                                                   ...{[fabricType]:{"fabric":value.fabric, "fabricImg":value.fabricImg, 
                                                   "brand":value.brand, "price":value.price, "src":value.src, "pos":Types[fabricType],
                                                   "single":value["single"], "style":value["style"], "id":value["id"], "fabricID":value["fabricID"], "discount":value["discount"], "brandImg" : value["brandImg"]
                                               }}
                                                }
                                               }}
                                            }
                                            const price = calPrice(updatedSel)
                                            // setStylesAll({
                                            //     "Coat":{},
                                            //     "Vest":{},
                                            //     "Shirt":{},
                                            //     "Pant":{}
                                            // })
                                            // // console.log("--------UPDATING FABRIC", updatedSel)

                                            getStyleImg({...updatedSel, ...{"price":price[0], "discount":price[1]}}, 1)
                                            // setSel(
                                            //     {...updatedSel, ...{"price":price[0], "discount":price[1]}}
                                            //     )
                                            // // console.log(sel)
                                        }}
                                    >
                                        {/* {( sel && sel["fabric"] && sel["fabric"][fabricType] && sel["fabric"][fabricType]["fabricID"] && sel["fabric"][fabricType]["fabricID"] === value["fabricID"]   || hover === key) && 
                                        <div className="sel-info-div">
                                            <img src={info} className="sel-info both_center"
                                                
                                            type="button"/>
                                        </div>} */}
                                        <div class="card-body1">
                                            <div className="s-f-price txt-small1"> $ {value.price} </div>
                                            <div className="s-f-foot">
                                                {value.fabric} | <label className="txt-small1" >{value.brand}</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {( sel && sel["fabric"] && sel["fabric"][fabricType] && sel["fabric"][fabricType]["fabricID"] && sel["fabric"][fabricType]["fabricID"] === value["fabricID"]) &&
                                    <div style={{"position":"absolute"}} className="s-f-i-c-bottom"
                                        
                                    >
                                        <div style={{"width":"fit-content", "zIndex":"10"}} className="both_center"
                                            type="button"
                                            
                                        >
                                            
                                                <img src={info} className="sel-info"
                                                    style={isMobile ? {"width":"18px"}:{}}
                                                />
                                                <b><label>Fabric info</label></b>
                                            
                                        </div>
                                        {/* <b><label className="fab_inf hori_center">
                                        {!isMobile ? <img src={info} className="sel-info"/> :<></>}
                                            Fabric info</label></b> */}
                                    </div> }
                                    {/* // : <div style={{"height":"18px", "width":"100%", "marginBottom":"10px"}} ></div>} */}
                                    
                                    
                                    
                                </div>
{/* //////////////////////////////////////////////// */}
                                
                                </>
                            )
                        })}
                        { Fabric && !Fabric[fabricType] && (
                            <>
                                <div class="col-6 mb-4 placeholder-glow">
                                    <div class="placeholder col-12 mb-4"><br/></div>
                                </div>
                                <div class="col-6 mb-4 placeholder-glow">
                                    <div class="placeholder col-12 mb-4"><br/></div>
                                </div>
                                <div class="col-6 mb-4 placeholder-glow">
                                    <div class="placeholder col-12 mb-4"><br/></div>
                                </div>
                                <div class="col-6 mb-4 placeholder-glow">
                                    <div class="placeholder col-12 mb-4"><br/></div>
                                </div>
                        </>
                        )

                        }
                    </div>
                </div>
            </div>
            </div>
            </>)
            }
        </div>
    )
}