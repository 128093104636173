import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import React from 'react';
import Login from "./Auth";
import HomePage from "./Homepage";
import PrivateRoute from "./privateRoute";
import NavBar from "./Navbar";
import Fabric from "./Fabric";
import Checkout from "./Checkout";
import Profile from "./Checkout/profile";
import Presets_main from "./Presets";
import Preset_item from "./Presets/Preset-item";
import Feel_fabric from "./Fabric/Feel";
import Appointment from "./Contact/appointment";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <div>
              <NavBar flag={0} />
                <HomePage />
              
                </div>
      },
      {
        path: "/profile",
        element: (
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          )
      },
      {
        path:"/login",
        element:<>
            <NavBar flag={1} />
            <Login/>
        </>
      },
      {
        path:"/custom",
        element:
        <React.Suspense fallback = "Loading...">
            <Fabric/>
        </React.Suspense>
      },
      {
        path:"/test",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Checkout/>
            </>
        </React.Suspense>
      },
      {
        path:"/test2",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={0} />
                <Presets_main/>
            </>
        </React.Suspense>
      },
      {
        path:"/test3",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Preset_item/>
            </>
        </React.Suspense>
      },
      {
        path:"/feel",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Feel_fabric/>
            </>
        </React.Suspense>
      },
      {
        path:"/appointment",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Appointment/>
            </>
        </React.Suspense>
      }

    ]
  }
]);

export default router;
