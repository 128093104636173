import sample from '../assets/samples/s1.png'
import options from '../assets/icon/options.png'
import darrow from '../assets/icon/darrow.png'
import uparrrow from '../assets/icon/uparrow.png'

import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewImages from '../Fabric/View/viewImages';
import body from '../assets/samples/BODY1.png'
import def_shirt from '../assets/samples/shirt/SHIRT1.png'

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function Cartcard({item, deleteFromCart}) {
    
    const [expand, setExpand] = useState(0)
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 480);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])
    return (
    <div style={{"width":"50vw"}}>
    <div className="cart-card"
        style={expand === 0 ? {"marginBottom":"30px"}:{}}
    >
        <Tooltip TransitionComponent={Zoom} 
        title= {Object.keys(item).includes("item_type") && item.item_type === 0 ? "Fabric Only" 
            : Object.keys(item).includes("item_type") && item.item_type === 1 ? "Preset": "Custom Suit"
            }
        >
        <div className='c-options' 
            style= {Object.keys(item).includes("item_type") && item.item_type === 0 ? {} : {"backgroundColor":"#14213D", "color":"white"}}
        >
            <div style={{"position":"relative", "width":"100%", "height":"100%"}}>
                <label className='FIT both_center'>
                    {Object.keys(item).includes("item_type") && item.item_type === 0 ? "F" 
                    : Object.keys(item).includes("item_type") && item.item_type === 1 ? "P": "C"
                    }
                </label>
            </div>
        </div>
                    
        </Tooltip>
        {/* <img src={options} className='c-options'/> */}

        <div className='cart-img'>
            {/* <img src={sample} className='c-im'/> */}
            <div style={{"position":"relative", "width":"100%", "height":"100%", "objectFit":"contain"}}
                className='verti_center'
            >
            
            {Object.keys(item).includes("item_type") && item.item_type === 1 ? 
                <>
                    {/* Presets */}
                </> 
                : Object.keys(item).includes("item_type") && item.item_type === 0 ? 

                <>
                    {/* Fabric feel */}
                    <img src={ item.src }
                        style={{"objectFit":"cover"}}
                        className= {"v-display-img-full"}
                        />
                </>

                : Object.keys(item).includes("item_type") && item.item_type === 2 ? 
                
                <>
                    {item.fabric.single && item.fabric.single.length >0 && item.fabric.single[0].typ === 1 ? 
                        (<>
                            {item.fabric.single && item.fabric.single.length >0 && item.fabric.single[0]["src"].map((it)=>{
                                return(
                                    <img src={ it }
                                            // onLoad={imageLoaded} 
                                        className= {"v-display-img-full"}/>
                                )
                            })

                            }
                        </>)
                        : (
                            <img src={ item.fabric.single[0].src }
                            // onLoad={imageLoaded} 
                            className= {"v-display-img-full"}/>)
                    }
                </> 
                
                : <>
                    <img src={ body }
                                            // onLoad={imageLoaded} 
                        className= {"v-display-img-full"}/>
                    <img src={ def_shirt }
                                            // onLoad={imageLoaded} 
                        className= {"v-display-img-full"}/>
                    {item && item.types.map((val, key)=>{
                        return(
                            <img src={ item && item.fabric && Object.hasOwn(item.fabric, val) ? item.fabric[val]["src"] : "" }
                                // onLoad={imageLoaded}
                                style={ 
                                     {"zIndex":50-item.fabric[val]["pos"]} } 
                                className= {item.types.includes("Pant") ? "v-display-img-full":"v-display-img"}/>
                        )
                    })}
                </>
            }
            
            
            </div>
        </div>
        
        <div className='cart-options'>
                
                <label className='c-o-e'
                    onClick={()=>{
                        const val = deleteFromCart(item.id)
                    }}
                >Delete</label>
                
                <div className='vertical-line'></div>
                {((Object.keys(item).includes("item_type") && item.item_type!=0) || !Object.keys(item).includes("item_type") ) && <label className='c-o-e1'
                    onClick={()=>{
                        sessionStorage.setItem("sel",JSON.stringify(item))
                        navigate('/custom')
                    }}
                >Edit item</label>}
        </div>
            
        <div className="cart-c-des verti_center">
            <div className='cart-des-left'>
                <div className="txt-font1 mobile-font ">
                    {item.name ? item.name: item.type}
                </div>
                
                {Object.keys(item).includes("item_type") && item.item_type === 2 &&<div className='mobile-item'>{item.fabric.brand}, {item.fabric.fabric}</div>}
                {!Object.keys(item).includes("item_type") && <div className='mobile-item'>
                    {item.types && item.types.map((val)=>{
                        return(
                            <span>{val},</span>
                        )
                    })}
                </div>}
                {Object.keys(item).includes("item_type") && item.item_type === 0 &&
                    <div className='mobile-item'>
                        <span><img src ={item.brandImg}
                            className='card_brandImg'
                        /> {item.brand} </span><br/>
                        <span> {item.material} ; </span>
                        <span> {item.pattern} </span>
                    </div>

                }
                
                
                
                <div>
                    {/* {item["style"] && Object.keys(item.style).map((v, k)=>(

                        <>{item.style[v]}  </>
                    ))} */}
                    {/* Teal Green, Two-Piece, Custom Pocket Square */}
                </div>
                
            </div>
            {((Object.keys(item).includes("item_type") && item.item_type!=0) || !Object.keys(item).includes("item_type") ) && <div className='cart-des-right txt-font1 mobile-font2'>
                {item.price.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            })}
            </div>}
        </div>
        {isMobile && (
                expand === 0 ? (
                    <span className='c-det' onClick={() => setExpand(1)}>
                        View Details
                    </span>
                ) : (
                    <span className='c-det' onClick={() => setExpand(0)}>
                        View Details
                    </span>
                )
            )}

            {((Object.keys(item).includes("item_type") && item.item_type!=0) || !Object.keys(item).includes("item_type") ) && !isMobile && (
                <img src={darrow} className='c-det' onClick={() => setExpand(expand === 0 ? 1 : 0)} />
            )}
        
        

        {/* <div className='c-det'>Details <img src={plus} style={{"transform":"scale(0.7)"}}/> </div> */}
    </div> 
    { expand == 1 &&
    <div style={{"display":"flex"}}>
        {/* <div></div> */}
        <div className='c-m-modal'>
            <div>Measurments</div>
            {item && item["measurements"]["type"] == 0 ? (
                <div className='c-m-t-1'>
                <div>
                    Upper Body : {item["measurements"]["upper_body"]}
                </div>
                <div>
                    Lower Body : {item["measurements"]["lower_body"]}
                </div>
                </div>
            ) : (
                <div className='c-m-t-1'>
                    <div>
                        <b>Upper Body </b> <br/>
                        Shoulder Width : {item["measurements"]["upper_body"]["sleeve_length"]},  
                        Sleeve Length : {item["measurements"]["upper_body"]["shoulder_width"]},  
                        Chest : {item["measurements"]["upper_body"]["chest_around"]},  
                        Stomach : {item["measurements"]["upper_body"]["stomach"]} 
                    </div>
                    <div>
                        <b>Lower Body </b> <br/>
                        Hips : {item["measurements"]["lower_body"]["hips"]},  
                        Leg Length : {item["measurements"]["lower_body"]["leg_length"]},  
                        Waist : {item["measurements"]["lower_body"]["pants_waist"]},  
                        Thigh : {item["measurements"]["lower_body"]["thighs"]}
                    </div>
                </div>
            )

            }

        </div>
    </div>
    }
    </div>
    );
}

export default Cartcard;