import React, { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate 
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.bundle.min.js"

import firebase from './firebase'

import HomePage from './Homepage';
import NavBar from './Navbar';

// import {addDoc} from './Firebase/firestore'

// import Login from "./Auth";
// import Profile from "./Checkout/profile";
// import Checkout from "./Checkout";
// import Presets_main from "./Presets";


import Preset_item from "./Presets/Preset-item";
import Measurement from "./Measurements";
import { useScrollTrigger } from "@mui/material";
import Header from "./Header";

const Fabric = React.lazy(()=>import('./Fabric'))
const Login = React.lazy(()=>import('./Auth'))
const Profile = React.lazy(()=>import('./Checkout/profile'))
const Checkout = React.lazy(()=>import('./Checkout'))
const Presets_main = React.lazy(()=>import('./Presets'))

// import Test from './Test';
// import Test1 from './Test/Test1';




// import CustomM from "./Measurements/customM";





function App() {
  
  return (
    
    <div className="App">
      {/* <HomePage /> */}
      <Outlet></Outlet>
    </div>

  );
}

export default App;
