import { useEffect, useMemo, useState,useRef, useContext } from "react";
import NavBar from "../Navbar";

import {getState, getUser, logout} from '../Firebase/auth'
import { useNavigate } from "react-router-dom";
import './profile.css'
import Order_card from "./order";

import close from '../assets/icon/close.png'

import nextArr1 from '../assets/icon/Larrow.png'
import prevArr1 from '../assets/icon/Rarrow.png'

import nextArr from '../assets/icon/nextL.png'
import prevArr from '../assets/icon/next.png'

import '../Firebase/auth'
import { db } from "../firebase";
import { getDocOnCondition } from "../Firebase/firestoreGet";
import ViewImages from "../Fabric/View/viewImages";
import { AuthContext } from "../Firebase/AuthProvider";

function Profile() {
    // const [User, setUser] = useState();

    const { user, running, userSignOut} = useContext(AuthContext);

    const navigate = useNavigate()
    const [Orders, setOrders] = useState()

    const [loading, setLoading] = useState(false)
    const [imgState, setImgState]=  useState(0)
    const [State, setState] = useState(1)
    
    const Wishlist = []
    const Adddress = []
    const Measurement = []

    const profile = ["Wishlist", "Order History","Shopping Cart", "Saved Addresses", "Measurements Profile", "Log out"]

    const [current, setCurrent] = useState(profile[0])
    

    const [modal, setModal] = useState(0)
    const [order, setOrder] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
    const [menuOpen, setMenuOpen] = useState(false);

    const sidebarRef = useRef(null);
    const overlayRef = useRef(null);
    // const User = useMemo(()=> getUser());
    const getOrders = async () => {
        try{
            const collection = db.collection("Orders")
            const orders = await getDocOnCondition(collection, "email", user.email)
            // console.log("Orders ", orders)
            return orders
        }catch(e){  
            // console.log("from fetching the order", e )
        }
    }

    useEffect(()=>{
        if(current === profile[1]){
            getOrders().then((data)=>{setOrders(data)}).catch((e)=>{console.log("from orders. ", e)})
        }
    }, [current])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    
    return ( 
    <div className="Profile">
        <div>
            <NavBar  {...{flag:2}}/>
            <div style={{"height":"80px"}}></div>
        </div>
        {modal === 1 && <>
        <div className="oder_opac"></div>
        <div className="order_modal both_center">
            <img src={close} className="o-m-close"
                type="button" onClick={()=>{
                    setImgState(0)
                    setModal(0)
                }}
            />
            <div className="order_modal_img">

            <img src={isMobile ? nextArr1 : nextArr} className='v-arr-l'
                            onClick={()=>{
                                if(imgState > 0){
                                    // console.log("Clicking")
                                    setLoading(true)
                                }
                                setImgState((curr)=>(
                                curr > 0 ? curr - 1 : 0
                            ))}}
                        />
                    <img src={isMobile ? prevArr1 : prevArr} className='v-arr-r'
                            onClick={()=>{
                                if(imgState < State-1){
                                    // console.log("clicking")
                                    setLoading(true)
                                    setImgState((curr)=>(
                                        curr < State ? curr + 1 : curr
                                    ))
                                }
                                
                                
                        }}
                            
                        />
                {order && <ViewImages
                    sel = {order}
                    loading = {loading}
                    setLoading={setLoading}
                    imgState={imgState}
                    setState1 = {setState}
                    setImgState={setImgState}
                    type = {1}
                />}

                        
            </div>
            <div className="order_modal_wrapper">
            <div className="order_modal_des">
                <div className="txt-font">{order.name}</div>
                <div>Order ID : {order.id}</div>
                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                <br/><div className="txt-font1">Total : { order.price.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            }) }</div>
                <div className="txt-font1">Status : {order.status ? order.status :"Order Received"}</div>
                <br/><br/>
                <div className="order_Address"> 
                    <div className="o-d-add">
                        <b>Delivery Adddress  </b>
                        <div>{order.Address.name}</div>
                        <div>{order.Address.phone}</div>
                        <div>{order.Address.address}</div>
                        <div>{order.Address.city}</div>
                        <div>{order.Address.state} | <div>{order.Address.pincode}</div></div>

                    </div>
                    <div className="o-b-add">
                        <b>Billing Adddress  </b>
                        <div>{order.Address.name}</div>
                        <div>{order.Address.phone}</div>
                        <div>{order.Address.address}</div>
                        <div>{order.Address.city}</div>
                        <div>{order.Address.state} | <div>{order.Address.pincode}</div></div>
                    </div>
                </div>
                
                
            </div>
            </div>
        </div>
        </>}
        {isMobile && (
            <>
                    <div className="burger-menu21" onClick={() => setMenuOpen(!menuOpen)}>
                        <span className="burger-menu-icon3">&#9776;</span>
                    </div>
                    
                    </>
                    
                )}
        <div className="profile_body">
        
            <div className={`profile-b-left ${isMobile ? (menuOpen ? 'show' : 'hidden') : ''}`} >
            {isMobile && (
                <span className="close-sidebar" onClick={() => setMenuOpen(false)}>&times;</span>
            )}
                <div className="profile-card">
                    <div className="Circle"> <label className="both_center title-font1"><b>
                        {user ? user.displayName.split(" ")[0][0]:""}
                    </b></label> </div>
                    <div className="txt-font">
                        {user ? user.displayName:""}
                    </div>
                    <div>{user ? user.email : ""}</div>
                    
                </div>
                <div className="profile-list">
                    {profile.map((value, key)=>{
                        return(

                        <div className="profile-l-e txt-font1"
                            style={value === current ? {"opacity":"1"} : {"opacity":"0.7"}}
                            onClick={()=>{
                                if(value === profile[profile.length - 1]){
                                    logout().then(()=>{
                                        navigate("/")                    
                                    }).catch(()=>{})                
                                }
                                else if(key === 2){
                                    navigate("/test")
                                }
                                else{
                                    setCurrent(value)    
                                }
                            }}
                            type="button"
                        >
                            {value}
                        </div>)
                    })}
                </div>
            </div>
            <div className="profile-b-right">
                <div className="txt-font">
                    {current}
                    <hr/>
                </div>
                {current === profile[0] ? 
                    <>
                        {Wishlist && Wishlist.length > 0 ? <></> :<>Nothing in here yet</>}
                    </> 
                    : current === profile[1] ? 
                    <>
                        {Orders && Orders.length > 0 ? 
                        <>
                            {Orders.map((value, key)=>{
                                return(
                                    <Order_card 
                                        order = {value}
                                        setOrder = {setOrder}
                                        setModal = {setModal}
                                    />
                                )
                            })}
                        </> : <>Nothing in here yet</>}
                    </> 
                    : current === profile[3] ? 
                    <>
                        {Adddress && Adddress.length > 0 ? <></> :<>Nothing in here yet</>}
                    </> 
                    : current === profile[4] ? 
                    <>
                        {Measurement && Measurement.length > 0 ? <></> :<>Nothing in here yet</>}
                    </> : <></>
                }
                
            </div>
        </div>

        
        {/* <div className="title-font">
            <br/><br/>
            Hi
            Profile Page + Previous Orders
            <button
                onClick={()=>{
                    
                }}
            >Logout</button>
        </div> */}
    </div> );
}

export default Profile;