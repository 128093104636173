import { createRef, useEffect, useRef, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import proc3 from '../assets/proc3.jpg'
import './measurements.css'
import { useNavigate } from 'react-router-dom';

import {getState} from '../Firebase/auth'

import length_eng from '../assets/measurments/VIDEOS/length_eng.mp4'
import length_hin from '../assets/measurments/VIDEOS/length_hin.mp4'

import chest_eng from '../assets/measurments/VIDEOS/chest_eng.mp4'
import chest_hin from '../assets/measurments/VIDEOS/chest_hin.mp4'

import stomach_eng from '../assets/measurments/VIDEOS/stomach_eng.mp4'
import stomach_hin from '../assets/measurments/VIDEOS/stomach_hin.mp4'

import hip_eng from '../assets/measurments/VIDEOS/hip_eng.mp4'
import hip_hin from '../assets/measurments/VIDEOS/hip_hin.mp4'

import shoulder_eng from '../assets/measurments/VIDEOS/shoulder_eng.mp4'
import shoulder_hin from '../assets/measurments/VIDEOS/shoulder_hin.mp4'

import sleeve_eng from '../assets/measurments/VIDEOS/sleeve_eng.mp4'
import sleeve_hin from '../assets/measurments/VIDEOS/sleeve_hin.mp4'

import neck_eng from '../assets/measurments/VIDEOS/neck_eng.mp4'
import neck_hin from '../assets/measurments/VIDEOS/neck_hin.mp4'

import bisep_eng from '../assets/measurments/VIDEOS/bisep_eng.mp4'
import bisep_hin from '../assets/measurments/VIDEOS/bisep_hin.mp4'


// lower Body
import leg_eng from '../assets/measurments/VIDEOS/waist_eng.mp4'
import leg_hin from '../assets/measurments/VIDEOS/waist_hin.mp4'

import waist_eng from '../assets/measurments/VIDEOS/waist_eng.mp4'
import waist_hin from '../assets/measurments/VIDEOS/waist_hin.mp4'

import circle_eng from '../assets/measurments/VIDEOS/circle_eng.mp4'
import circle_hin from '../assets/measurments/VIDEOS/circle_hin.mp4'

// import hip_eng from '../assets/measurments/VIDEOS/hip_eng.mp4'
// import hip_hin from '../assets/measurments/VIDEOS/hip_hin.mp4'

import thigh_eng from '../assets/measurments/VIDEOS/thigh_eng.mp4'
import thigh_hin from '../assets/measurments/VIDEOS/thigh_hin.mp4'

import bottom_eng from '../assets/measurments/VIDEOS/bottom_eng.mp4'
import bottom_hin from '../assets/measurments/VIDEOS/bottom_hin.mp4'

////
import play from '../assets/icon/play.svg'

import arr_l from '../assets/ALeft.png'
import arr_r from '../assets/ARight.png'
import { handleFireBaseUpload } from '../Firebase/firestore';

function CustomM({M, setM, setLoading, setPicModal, mState, setMState}) {

    

    const [final, setFinal] = useState(JSON.parse(sessionStorage.getItem("sel")))
    
    const [mvideos, setVideos] = useState({
        1:[ {"src":[length_eng, length_hin], "name":"Length"},
            {"src":[chest_eng, chest_hin], "name":"Chest"},
            {"src":[stomach_eng, stomach_hin], "name":"Stomach"},
            {"src":[hip_eng, hip_hin], "name":"Hip"},
            {"src":[shoulder_eng, shoulder_hin], "name":"Shoulder"},
            {"src":[sleeve_eng, sleeve_hin], "name":"Sleev Length"},
            {"src":[neck_eng, neck_hin], "name":"Neck"},
            {"src":[bisep_eng, bisep_hin], "name":"Bisep"}],
        2:[{"src":[leg_eng,leg_hin ], "name":"Leg Length"},
            {"src":[waist_eng,waist_hin ], "name":"Waist"},
            {"src":[circle_eng,circle_hin ], "name":"Crotch"},
            {"src":[hip_eng,hip_hin ], "name":"Hip"},
            {"src":[thigh_eng,thigh_hin ], "name":"Thighs"},
            {"src":[bottom_eng,bottom_hin ], "name":"Knees"}
        ],
        0 : [""]
    })

    const [lang, setLang] = useState(0)

    const [currVideo , setCurrVideo] = useState({
        ...mvideos[1][0], ...{"src":mvideos[1][0]["src"][lang], "id":0}
    })

    useEffect(()=>{

        if(mState>0){
            
            setCurrVideo( {...currVideo, ...{"src": mvideos[mState][currVideo["id"]][lang] } })
        }
    }, [lang])

    useEffect(()=>{
        if(mState > 0){
            setCurrVideo({
                ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
            }
            )
        }
    }, [mState])
    
    const notify = () => toast.error('Please add all Details', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });

    const [inpts, setInputs] = useState()
    const navigate = useNavigate();

    const L1 = useRef()
    const L2 = useRef()
    const L3 = useRef()
    const L4 = useRef()
    const L5 = useRef()
    const L6 = useRef()

    const [side, setSide] = useState()
    const [pSide, setPside] = useState()

    const [front, setfront] = useState()
    const [pfront, setPfront] = useState()

    useEffect(()=>{
        if (!side) {
            setPside(undefined)
          return
      }
          const objectUrl = URL.createObjectURL(side)
          setPside(objectUrl)
    
          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(objectUrl)
      }, [side])

      useEffect(()=>{
        if (!front) {
            setPfront(undefined)
          return
      }
          const objectUrl = URL.createObjectURL(front)
          setPfront(objectUrl)
    
          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(objectUrl)
      }, [front])
    // const inpts = useRef([]);

    useEffect(()=>{
        
        // console.log("calling use effect from measurements")
        setInputs([createRef(""), createRef(""), createRef(""), createRef(""),
        createRef(""), createRef(""), createRef(""), createRef(""),
        createRef(""), createRef(""), createRef(""), createRef("")])
    }, [])

    // const [mState, setMState] = useState(0)
    
    return ( 
        <div className="c-m hori_center">

                <div>
                    {/* <button onClick={notify}>Notify!</button> */}
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
            </div>

            {mState == 0 && inpts && inpts.length > 0 ? (
                <div>
                    <div className="txt-font hori_center"
                        style={{"width":"fit-content"}}
                    >

                        Measurement
                    </div>
                    <div className="c-m-des1 hori_center">
                        Accurate measurements help us deliver the best experience you can have.
                        Refer to the video here in case of any confusion on how to measure a particular entity.
                        You can check out available Presets here. 
                    </div>

                    <div className='c-m-input0 hori_center' style={{display:"flex", flexDirection:"column" ,gap:"20px"}}>
                        <div className='txt-font1 gen-mob FIT_W'> <b>General</b> </div>
                        <div className='input-wrap'>
                            <div className='name-mob'>Name <label style={{"color":"red"}}><b>*</b></label> </div>
                            <input className='c-m-i0' placeholder='Your Name' ref={inpts[0]}
                                onChange={()=>{
                                    // console.log(name.current.value);
                                }}
                            />
                        </div>
                        <div style={{display:"flex", gap:"20px"}}>
                            <div className='input-wrap hei-mob'>
                                <div>Height (Mt) <label style={{"color":"red"}}><b>*</b></label> </div>
                                <input className='c-m-i2' placeholder='Height' ref={inpts[1]} required
                                    onChange={()=>{
                                        // console.log(inpts[1].current.value);
                                    }}
                                />
                            </div>
                            
                            <div className='input-wrap weight-mob'>
                                <div>Weight (kg) <label style={{"color":"red"}}><b>*</b></label></div>
                            <input className='c-m-i2 ' placeholder='Weight' ref={inpts[2]} required
                                onChange={()=>{
                                    // console.log(inpts[2].current.value);
                                }}
                            /></div>

                            <div className='input-wrap age-mob '>
                                <div>Age <label style={{"color":"red"}}><b>*</b></label> </div>
                            <input className='c-m-i2' placeholder='Age' ref={inpts[3]} required
                                onChange={()=>{
                                    // console.log(inpts[3].current.value);
                                }}
                            /></div>

                            {/* <div className='input-wrap gender-mob'>
                                <div>Gender <label style={{"color":"red"}}><b>*</b></label> </div>
                            <input className='c-m-i2' placeholder='Gender' ref={inpts[4]} required
                                onChange={()=>{
                                    // console.log(inpts[4].current.value);
                                }}
                            /></div> */}

                        </div>
                        <div style={{alignContent:"center"}} className='FIT_W'>
                            <div className='c-m-b-c hori_center'>
                                <div className='input-wrap front-view-mob'
                                    onMouseEnter={()=>{setPicModal(1)}}
                                    onMouseLeave={()=>{setPicModal(false)}}
                                    type=  "button"
                                >
                                    <div><b>Front View Image</b>
                                    <label style={{"color":"red"}}><b>*</b></label>
                                    </div>
                                    
                                    <input className='' placeholder='Front View' type='file'
                                    
                                        onChange={(e)=>{setfront(e.target.files[0])}}
                                    
                                /></div>

                                <div className='input-wrap side-view-mob'
                                    type=  "button"
                                    onMouseEnter={()=>{setPicModal(2)}}
                                    onMouseLeave={()=>{setPicModal(false)}}
                                >
                                <div><b>Side View Image</b>
                                <label style={{"color":"red"}}><b>*</b></label></div>
                                <input className='' placeholder='Side View' type='file'
                                    onChange={(e)=>{setSide(e.target.files[0])}}
                                /></div>

                            </div>
                        </div>
                        
                    </div>
                </div>
            )
                
            :
            (<div className='c-m-body'>
                <div className='c-m-left'>

                
                    <div className="txt-font head1">
                        Enter your Measurement
                    </div>
            

                    <div className="c-m-des">
                        Accurate measurements help us deliver the best experience you can have.
                        Refer to the video here in case of any confusion on how to measure a particular entity.
                    </div>

                    <div className='c-m-input'>
                        <div className='c-m-i-box hori_center'>
                            <div className='txt-font1 body-head'
                                style = {{"marginBottom":"20px"}}
                            >
                                
                              <b>  {mState === 1 ? "Upper Body" : mState === 2 ? "Lower Body" : ("") }</b>
                            </div>

                            {mState == 1 && inpts && inpts.length > 0 ?
                            (<div>
                                <div className='c-m-i-r'>
                                    
                                    <div className='input-wrap mob-input mob-right-d3'>
                                        <div>Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={play}
                                            type = "button"
                                            onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Length' ref={inpts[4]} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l2'>
                                        <div>Chest Around
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][1], ...{"src":mvideos[mState][1]["src"][lang], "id":1}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Chest Around' ref={inpts[5]} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>

                                    <div className='input-wrap mob-input mob-right-d2'>
                                        <div
                                            
                                        >Stomach
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={play}
                                            type = "button"
                                            onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][2], ...{"src":mvideos[mState][2]["src"][lang], "id":2}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "right":"0"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Stomach' ref={inpts[6]} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-d2'>
                                        <div
                                            
                                        >Hip
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={play}
                                            type = "button"
                                            onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][3], ...{"src":mvideos[mState][3]["src"][lang], "id":3}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "right":"0"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Hip' ref={inpts[7]} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='c-m-i-r'>
                                
                                    <div className='input-wrap mob-input mob-input-d1'>
                                        <div>Shoulder Width
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={play}
                                            type = "button"
                                            onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][4], ...{"src":mvideos[mState][4]["src"][lang], "id":4}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1' placeholder='Shoulder Width' ref={inpts[8]} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-input-l1'>
                                        <div>Sleeve Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={play}
                                            type = "button"
                                            onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][5], ...{"src":mvideos[mState][5]["src"][lang], "id":5}
                                                    }
                                                    )
                                                }}
                                                
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />

                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1' placeholder='Sleeve Length' ref={inpts[9]} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l3'>
                                        <div>Neck
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][6], ...{"src":mvideos[mState][6]["src"][lang], "id":6}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Neck' ref={inpts[10]} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l3'>
                                        <div>Bisep
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][7], ...{"src":mvideos[mState][7]["src"][lang], "id":7}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Bisep' ref={inpts[11]} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>) : mState == 2 ?
                            (<div>   
                                <div className='c-m-i-r1'>
                                    <div className='input-wrap mob-input mob-input-l1'>
                                        <div>Leg Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={play}
                                            type = "button"
                                            onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Leg Length' ref={L1} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l2'>
                                        <div>Pants Waist
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][1], ...{"src":mvideos[mState][1]["src"][lang], "id":1}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Pants Waist' ref={L2} id='jnf' />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-input-d1'>
                                        <div>Circle
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][2], ...{"src":mvideos[mState][2]["src"][lang], "id":2}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Circle' ref={L4} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='c-m-i-r1'>
                                <div className='input-wrap mob-input mob-right-l3'>
                                        <div>Hip
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][3], ...{"src":mvideos[mState][3]["src"][lang], "id":3}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Hip' ref={L3} id='jnf' />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-d2'>
                                        <div>Thigh
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][4], ...{"src":mvideos[mState][4]["src"][lang], "id":4}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Thigh' ref={L5} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-d3'>
                                        <div>Bottom
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={play}
                                        type = "button"
                                        onClick={()=>{
                                                    setCurrVideo({
                                                        ...mvideos[mState][5], ...{"src":mvideos[mState][5]["src"][lang], "id":5}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Bottom' ref={L6} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                </div>
                            </div>) : (<></>)}

                        </div>
                    </div>
                </div>

                <div className='c-m-right'>
                    <div>
                        <div className='lang hori_center'>
                        <label className={ lang === 1 ? "lang_ind" :  "lang_ind lang_s"}
                            type="button"
                            onClick={()=>{setLang(0)}}
                        >English</label>
                        <label 
                            type="button"
                            className={ lang === 0 ? "lang_ind" :  "lang_ind lang_s"}
                            onClick={()=>{setLang(1)}}
                        >Hindi</label>
                        </div>
                    </div>
                    <div className="c-m-video both_center">
                            <video className='c-m-img'
                                src={ currVideo["src"] } controls
                            >
                            </video>
                            {/* <img src={proc3} className='c-m-img'/> */}
                            
                            <div className='c-m-v-des'>
                                {/* <img  src  = {arr_l} style={{"width":"25px", "position":"absolute", "left":"0"}}
                                    
                                /> */}
                                <div
                                    className='c-m-v-info hori_center'
                                >
                                <div > <b> Measurement {currVideo["name"]} length </b></div>
                                <div style={{"fontSize":"0.7rem"}} > <span style={{"color":"red"}} >*</span> The Video contains instructions you should follow to get Measured at home easily. Please add the measurments carefully. The final Product will depend on the values you add</div>
                                </div>
                                {/* <img  src  = {arr_r} style={{"width":"25px","position":"absolute", "right":"0"}}/> */}
                            </div>

                    </div>
                </div>
            </div>
            
            )}
            

            
                  
            

            <div className="c-m-buttons hori_center proceed-but2">   

                { mState === 0 ? "" : <button className="Butt1 "
                    onClick={()=>{
                        setMState((prev)=>prev===0 ? prev = 0 : prev-1)
                    }}
                ><span>Go Back</span></button>}
                <button className="Butt1"
                    onClick={async()=>{
                        if(mState == 0){
                            if(
                                !inpts[0].current.value ||
                                !inpts[1].current.value ||
                                !inpts[2].current.value ||
                                !inpts[3].current.value ||
                                !inpts[4].current.value ||
                                !side ||
                                !front
                            ){
                                notify()
                            } else{
                            // console.log("this is ok")
                            setLoading(true)
                            const F = await handleFireBaseUpload((new Date()).getTime().toString(), front, 'User/front')
                            const S = await handleFireBaseUpload((new Date()).getTime().toString(), side, 'User/side')
                            setLoading(false)
                            setFinal({...final, ...{
                                "name" : inpts[0].current.value,
                                
                                "measurements" : {
                                    "type":1,
                                    "height":inpts[1].current.value,
                                    "weight":inpts[2].current.value,
                                    "age":inpts[3].current.value,
                                    "front_view" :  F,
                                    "side_view" :  S,
                                }
                            }
                        })
                            // console.log("this is also ok")
                            // console.log( inpts[1].current.value, inpts[2].current.value, inpts[3].current.value,inpts[4].current.value)
                            // console.log("this is also ok")
                            setMState((prev)=>prev===2 ? prev=2 : prev+1)
                            }
                        }else if(mState == 1){
                            
                            setFinal({...final, ...{"measurements":{...final.measurements, ...{
                                    "upper_body":{
                                        "sleeve_length":inpts[5].current.value,
                                        "chest_around":inpts[6].current.value,
                                        "neck":inpts[7].current.value,
                                        "shoulder_width":inpts[8].current.value,
                                        "stomach":inpts[8].current.value,
                                        "length":inpts[8].current.value,
                                    }
                                }
                            }
                        }
                        })
                        setMState((prev)=>prev===2 ? prev=2 : prev+1)
                            // console.log(inpts[5].current.value,inpts[6].current.value,inpts[7].current.value,inpts[8].current.value,inpts[9].current.value,inpts[10].current.value)
                        }else{
                            
                            
                            let data = {...final}
                            data["measurements"]["lower_body"] = {
                                "leg_length":L1.current.value,
                                "waist":L2.current.value,
                                "hip":L3.current.value,
                                "circle":L4.current.value,
                                "thigh":L5.current.value,
                                "bottom":L6.current.value
                            }
                            // console.log(L1.current.value,L2.current.value,L3.current.value,L4.current.value)
                            sessionStorage.removeItem("sel")
                            let currC = localStorage.getItem("sel")

                            if( Object.hasOwn(data, "id") ){
                                let local = JSON.parse( currC)
                                local.forEach((k, i)=>{
                                    if(k["id"] === data["id"]){
                                        local[i] = data
                                    }
                                })
                                localStorage.setItem("sel", JSON.stringify(local))
        
                                // console.log("updated Item")
                                
                            }else{

                            if(currC && JSON.parse(currC).length > 0){
                                let upC = JSON.parse(currC)
                                // console.log("curr",upC)
                                let c = upC[upC.length - 1]["id"] + 1
                                data["id"] = c
                                upC.push(data)
                                
                                localStorage.setItem("sel", JSON.stringify(upC))
                            }else{
                                data["id"] = 1
                                localStorage.setItem("sel", JSON.stringify([data]))
                            }
                        }
                            // console.log("data", JSON.parse(localStorage.getItem("sel")))
                            navigate('/test');
                        }
                        
                        
                        
                    }}
                >
                   <span>{mState===2 ? "Checkout" : "Proceed"}</span></button>
            </div>
        </div> 
    );
}

export default CustomM;