import React, { useState } from 'react';
import './MobileNavbar.css';
import Logo from './assets/download.svg';
import { useNavigate } from 'react-router-dom';
import { getState } from './Firebase/auth';

export default function MobileNavBar() {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const accountClick = () => {
    if (getState()) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="MobileNavBar">
      <div className="nav-mobile-header">
        <img src={Logo} alt="Logo" className="nav-mobile-logo hori_center" />
        <button onClick={toggleMobileMenu} className="burger-button11">
          &#9776;
        </button>
      </div>

      {isMobileMenuOpen && (
        <div className="nav-mobile-menu">
          <div onClick={() => { navigate('/'); setIsMobileMenuOpen(false); }} type="button">
            Home
          </div>
          <div onClick={() => { navigate('/'); setIsMobileMenuOpen(false); }} type="button">
            About Us
          </div>
          <div onClick={() => { navigate('/custom'); setIsMobileMenuOpen(false); }} type="button">
            Customize
          </div>
          <div onClick={() => { navigate('/test'); setIsMobileMenuOpen(false); }} type="button">
            Cart
          </div>
          <div onClick={() => { accountClick(); setIsMobileMenuOpen(false); }} type="button">
            Account
          </div>
        </div>
      )}
    </div>
  );
}
