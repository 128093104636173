
import { useState } from 'react';
import Logo from './assets/logo_name_nw.svg'


import insta from './assets/icon/social/instagram.png'
import fb from './assets/icon/social/fb.png'
import pin from './assets/icon/social/pinterest.png'
import twitter from './assets/icon/social/twitter.png'
import yt from './assets/icon/social/youtube.png'
import whatsapp from './assets/icon/social/whatsapp.png'

export default function Foot(){
    
    const [social, setSocial] = useState([{"src":insta, "link":"https://www.instagram.com/maviinci.in/"},
        {"src":fb, "link":"https://www.facebook.com/people/maviinciin/61556379716484/"},
        {"src":pin, "link":"https://in.pinterest.com/maviincifashion/"},
        {"src":twitter, "link":"https://x.com/maviincifashion?t=3Hy6jKr1NxuBUemXqMeYUA&s=08"}, 
        {"src":yt, "link":"https://www.youtube.com/@MAVIINCI_FASHION"}, 
        {"src":whatsapp, "link":"https://wa.me/918871081395"}, 
        ] ) 
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    return(
        <div className="foot1">
            <div className="foot_container">

                {!isMobile && <div className="foot_logo">
                    <img src={Logo} />
                    <div className="foot_logo tag_f">Where fashion meets the future</div>
                </div>}

                <div className="foot_right txt-font1">
                    <div className="footer_links">
                        <ul className="foot_list">
                            <li>Home</li>
                            <li>About Us</li>
                            <li>Contact</li>
                            <li>Cutstom Tailored</li>
                            <li>Explore </li>
                        </ul>
                    </div>
                    <div className="foot_links">
                        <ul className="foot_list">
                            <li>Blue Pearl Industries</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                    <div className='social'>
                        {social && social.map((val, key)=>{
                            return(
                                <a href={val.link}><img src={val["src"]} className='social_l'/></a>
                            )
                        })}
                    </div>
                </div>
                <div className='address_f txt-font1'>
                    Address : <br/>
                    Shanti Nagar Bhilai-3<br/>
                    Dist-durg chhattisgarh <br/>
                    Pin 490021<br/>
                </div>
            </div>
            
            
        </div>
    )
}