import axios from 'axios';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import './address.css'
import { json, useNavigate } from 'react-router-dom';
import { getDataFromCollection } from '../Firebase/firestoreGet';


import Address_ind from './address_ind';
import {
    GetCountries,
    GetState,
    GetCity,
    GetLanguages, //async functions
  } from "react-country-state-city";
import { AuthContext } from '../Firebase/AuthProvider';

const add = [{
    name:"Raghavewnfioweinof",
    last_name:"k",
    phone:"782765431", 
    address:"2nd Floor, A Block, Ashok Nagar fnwnfwemnfwe", 
    state:"Maharastra", 
    city:"Mumbai",
    pincode:"500078"
},
{
    name:"Raghav",
    last_name:"k",
    phone:"782765431", 
    address:"2nd Floor, A Block, Ashok Nagar fnwnfwemnfwe", 
    state:"Maharastra", 
    city:"Mumbai",
    pincode:"500078"
},
{
    name:"Raghav",
    last_name:"k",
    phone:"782765431", 
    address:"2nd Floor, A Block, Ashok Nagar fnwnfwemnfwe", 
    state:"Maharastra", 
    city:"Mumbai",
    pincode:"500078"
}
]

const paymentURL= "https://suits-backend-jey7.vercel.app"
// http://localhost:1337
// https://suits-backend-jey7.vercel.app

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

function Address({data, setData, setPage, payment, paymentStatus, setOrder}) {

    const { user} = useContext(AuthContext);

    const navigate = useNavigate()

    const [inpts, setInputs] = useState([createRef(""), createRef(""), createRef(""), createRef(""),
                                        createRef(""), createRef("")])

    const [inpts_b, setInputs_b] = useState([createRef(""), createRef(""), createRef(""), createRef(""),
                                            createRef(""), createRef("")])

    const [address, setAddress] = useState(add)

    const [fromSaved, selectAddress] = useState();
    const [fromManual, setAddressManual] = useState();
    
    // const [order, setOrder] = useState({})
    
    const [stateid, setStateid] = useState(0);
    const [cityid, setCityid] = useState(0);

    const [stateid_b, setStateid_b] = useState(0);
    const [cityid_b, setCityid_b] = useState(0);

    const [country, setCounty] = useState( {"c":"India","id":101} );

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [billing, setBilling] = useState(1)
  //////////
    
    useEffect(()=>{
        // JSON.parse(localStorage.getItem("sel"))
        getDataFromCollection("address", 1).then((data)=>{
                // console.log("address :) ", data)
        }).catch(()=>{

        })
    }, [])

    useEffect(() => {
        GetState(country.id).then((result) => {
            setStateList(result);
        });
      }, []);

    const addFunc = (typ = 1)=>{
        const address = {
            "name":inpts[0].current.value,
            "last_name":inpts[1].current.value,
            "phone":inpts[2].current.value,
            "address":inpts[3].current.value +" , " + inpts[4].current.value,
            "state":stateList[stateid],
            "city": cityList[cityid],
            "pincode":inpts[5].current.value
        }
        setData({...data,... {"address":address}})

        if(typ != 1){
            
            // console.log("Added to the DB", address)
        }
    }

    const paymentVerification = async(response, atype = 0)=>{
        try{
            const params = {
                "razorpay_payment_id": response.razorpay_payment_id,
                "razorpay_order_id": response.razorpay_order_id
            }
            const headers = {
              "x-razorpay-signature":  response.razorpay_signature,
              "Content-Type" : "application/json"
            }

            const options = {
                method : 'POST'
            }
            
            
            const resp = await fetch(`${paymentURL}/verification`, {method : 'POST', headers:headers, body:JSON.stringify(params)})
            const res = await resp.json()
            
            // console.log("after payent",res)
            
            paymentStatus({
                "id" : 0, 
                "status": res["status"] === 200
            })
            
            
        }catch(e){
            // console.log("verification e", e)
        }
    }

    async function displayRazorpay(data1, atype = 1) {

        console.log("Address Data", data1)
		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

        const headers = {
            "Content-Type" : "application/json"
        }

        const params ={
            "amount" : data1["price"]
        }

        console.log(params)
        
		const resp = await fetch(`${paymentURL}/razorpay`, { method: 'POST', headers:headers,body:JSON.stringify(params)});
        const data = await resp.json();
        
        setOrder((prev)=> prev = {...prev, ...{"razorpay_order_id" : data["id"]}} ) 

		// console.log("first API", data)

		const options = {
			key: 'rzp_test_K3cYe7qbI9KqX7',
			currency: data.currency,
			amount: data.amount.toString(),
			order_id: data.id,
			name: 'MAVIINCI',
			description: 'Thank you for Purchase',
			image: `${paymentURL}/logo.svg`,
			handler: function (response) {
                paymentStatus({
                    "id" : 2, 
                    "status":"started"
                })
                setOrder(response)
                paymentVerification(response, atype).then(()=>{})
			},
			prefill: {
				name: data1["address"].name ,
				email: user.email,
				phone_number: data1["address"].phone
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()
	}
    return ( 
    <div className="Address">
        <div onClick={()=>{
            setPage(0)
        }}
            style={{"width":"fit-content"}}
        >
            {/* <img src={arr}/> */}
            <u>Back to Cart</u>
        </div>
        <div className="txt-font" style={{"marginBottom":"40px"}}>Select Delivery Address</div>

        <div className='saved-address'>
        <div className='test-acc'>
        <div class="accordion accordion-flush" id="accordionFlushExample">
                {address && address.length > 0 && <div className="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button show" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Your Saved Addresses
                </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    {address.map((value, key)=>{
                        return(
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value={key}
                                    onChange={(e)=>{
                                        // selectAddress(address[e.target.value])
                                        setData({...data,... {"address":address[e.target.value]}})
                                        // console.log("checked :", e.target.value, address[e.target.value])
                                    }}
                                />
                                <div class="form-check-label" for="exampleRadios1">
                                    {value.name} | {value["address"]}, {value["state"]}, {value["city"]},{value["pincode"]}
                                </div>
                            </div>
                        )
                    })

                    }

                    <button className='Butt1 hori_center'
                        onClick={()=>{
                            if(data["address"]){
                                //paymentVerification().then(()=>{})
                                displayRazorpay(data, 1).then((data)=>{console.log("after payment", data)})
                            }else{
                                // Error toast
                                // console.log("Please select an address ")
                            }
                        }}
                    > <span>Continue</span> </button>
                </div>
                </div>
            </div>}

            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
                    disabled = {address && address.length > 0 ? false : true}
                >
                    
                    Add Delivery Address
                </button>
                </h2>
                <div id="flush-collapseTwo" class={address && address.length > 0 ? "accordion-collapse collapse" : "accordion-collapse collapse show"} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <Address_ind 
                        country ={country}
                        inpts = {inpts}
                        stateList = {stateList}
                        stateid = {stateid} 
                        setStateid = {setStateid}
                        cityList = {cityList} 
                        cityid={cityid}
                        setCityid = {setCityid} 
                        setCityList = {setCityList}
                        addFunc = {addFunc}
                        type = {0}
                    />
                </div>
                </div>
            </div>
        </div>
        
        </div>
        </div>
        
        <div className='Billing_add'
            style={ !billing ?  {"width":"fit-content", "height":"fit-content", "border":"1px solid rgb(20, 33, 61, 0.5)", "padding":"5%"}:{}}
        >
            <b>Billing Address : Same as Delivery Address  &nbsp;</b>
            <input class="" type="checkbox" defaultChecked = {billing} id='billing_a'
                onChange={()=>{
                    setBilling(document.getElementById("billing_a").checked)
                    console.log(document.getElementById("billing_a").checked)
                }}
                style={{"border":"1px solid black"}}
            />

            {!billing && 
                <div style={{"marginTop":"10px"}}>
                    <Address_ind 
                        country ={country}
                        inpts = {inpts_b}
                        stateList = {stateList}
                        stateid = {stateid_b} 
                        setStateid = {setStateid_b}
                        cityList = {cityList} 
                        cityid={cityid_b}
                        setCityid = {setCityid_b} 
                        setCityList = {setCityList}
                        addFunc = {addFunc}
                        type = {1}
                    />
                </div>}
        </div>

        
        

    </div> );
}

export default Address;