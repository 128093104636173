const orderStatus = ["Received", "Confirmed", "Processing", "In Transit", "Order Delivered"]

function Order_status({status}) {
    return ( 
    <div className="order_status">
        
        <div className="o-s-line1 verti_center"></div>
        <div className="o-s-line verti_center"></div>
        

        <div className="o-s">
            
            <div className="o-s-circle">
            {orderStatus.map((val, key)=>{
                return(
                <div className={key===0 ? "o-s-single" : "o-s-single-margin"} >
                    <div className={key <= status ? "o-s-round-c o-s-done" : "o-s-round"}></div>
                </div>) 
            })}
            </div>

        </div>
        <div>

        </div>
        
    </div> );
}

export default Order_status;