
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDIuRhzgQs1D8nAVkV_V3GJvCsLDzIbDQc",
    authDomain: "maviinvi.firebaseapp.com",
    projectId: "maviinvi",
    storageBucket: "maviinvi.appspot.com",
    messagingSenderId: "156367186367",
    appId: "1:156367186367:web:9170179cdb5e641972990a"
  };
  

firebase.initializeApp(firebaseConfig);

export const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
export const auth = app.auth();
export const storage = app.storage();

// firebase.auth().onAuthStateChanged((user) => {
//     if (user) {
//       // User is signed in, see docs for a list of available properties
//       // https://firebase.google.com/docs/reference/js/v8/firebase.User
//       var uid = user.uid;
//       // ...
//     } else {
//       // User is signed out
//       // ...
//     }
// });

export default firebase;