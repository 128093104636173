import { fabricFilter } from '../../Data';
import '../fabric.css'

function FilterFabric() {
    return ( 
        <div className="f-filter">
                            
        <div className="f-filterBody">
            <label className="txt-font"
                style={{"marginLeft":"10%", "marginTop":"10vh"}}
            > <b> Filters </b></label>

            {fabricFilter && fabricFilter.map((value, key)=>{
                return(
                    <div className="f-fil-comp hori_center">
                        <label className="txt-font1"
                            style={{"margin":"20px 0px"}}
                        >{value["type"]}</label>
                        
                        {value["q"] === 1 ? <div class="container">
                            <a data-toggle="tooltip" title="Hooray!"><div class="row gx-0">
                                {value["attr"].map((val, k)=>{
                                    return(
                                        <div class="col-3 f-balls mb-2"
                                            style={{"background":val["value"]}}
                                        >
                                            {/* 1 of 2 */}
                                        </div>
                                    )
                                })}
                            </div></a>
                        </div>
                        :value["q"] === 0 ? <>
                            {value["attr"].map((val, k)=>{
                                    return(
                                        <div className='f-c-comp hori_center'>
                                            <label>{val.name}</label>
                                            <input class="form-check-input f-c-i" type="checkbox" value="" id="flexCheckDefault" 
                                                style={{"border":"1px solid black"}}
                                            />
                                        </div>
                                    )
                                })}
                        </>
                        : <>
                            <div className='f-c-comp hori_center'>
                                            <label>High to Low</label>
                                            <input class="form-check-input f-c-i" type="checkbox" value="" id="flexCheckDefault" 
                                                style={{"border":"1px solid black"}}
                                            />
                            </div>
                            <div className='f-c-comp hori_center'>
                                            <label>Low to High</label>
                                            <input class="form-check-input f-c-i" type="checkbox" value="" id="flexCheckDefault" 
                                                style={{"border":"1px solid black"}}
                                            />
                            </div>
                            
                        </>
                        }

                        
                        
                    </div>
                )
            })}

            
        </div>
    </div> );
}

export default FilterFabric;