import { useEffect, useState } from "react";
import Cartcard from "./cartCard";
import { useNavigate } from "react-router-dom";

import Switch from '@mui/material/Switch';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function Cart({setChange}) {
    const navigate = useNavigate()
    const [cartItems, setCart] = useState(JSON.parse(localStorage.getItem("sel")))

    const [ inCart, setInCart ] = useState()

    const [toggle, setToggle] = useState(false)


    useEffect(()=>{
        // console.log(cartItems)
        generateCart()
    }, [toggle, cartItems])

    const generateCart = ()=>{
        try{
            let carts = []

            for(let i =  0;i<cartItems.length;i++){

                if(toggle && ( !Object.keys(cartItems[i]).includes("item_type"))){
                    const typs  = cartItems[i].types
                    typs.forEach(element => {
                        carts.push({
                            "item_type":2,
                            "type" : element,
                            "fabric": cartItems[i].fabric[element],
                            "price" : cartItems[i].fabric[element].price,
                            "style": cartItems[i].style[element],
                            "measurements":cartItems[i].measurements
                        })    
                    });
                    
                } else{
                    carts.push(cartItems[i])
                } 
            }
            console.log("From cart :", carts)
            setInCart(carts)

        } catch(e){
            console.log(e)
        }
    }

    function deleteFromCart(id){
        // console.log("id received", id)
        let data = JSON.parse(localStorage.getItem("sel"))
        for(let i=0;i<data.length;i++){
            
            if(data[i].id == id){
                
                if(data.length == 1){data = []}else{data.splice(i, 1)}
                // console.log("After delete", data)
                localStorage.setItem("sel", JSON.stringify(data))
                setCart(data)
                setChange((prev)=>!prev)
                return true
            }
        }
        
        return false
    }
    return (
    <div className="Cart">

        

        { cartItems && cartItems.length>0 && <div className="txt-font urcart" style={{"marginBottom":"20px", "display":"flex", "position":"relative"}}>
            Your Cart
            <Tooltip TransitionComponent={Zoom} title="Switch the Toggle to see different View of the Cart. Switch the Toggle off to see the Cloth together and Switch it ON to see the Fabrics independently">
                    {/* <Button>Zoom</Button> */}
                    <div className="txt-font1 cart_switch"
                
                    >Switch Cart <Switch {...label} defaultChecked 
                        // onChange={(e)=>{console.log(e.target.value)}}
                        onChange={() => {
                            // console.log(!fabSame);
                            setToggle(prevCheck => !prevCheck) ; }}
                        value={toggle}
                        checked={!!toggle}
                    /> </div>
        </Tooltip>
            </div>}
        {inCart && inCart.length >0 && inCart.map((value, key)=>{
            return(
                
                <Cartcard item={value} key={key} deleteFromCart={deleteFromCart}
                            toggle = {toggle} setToggle = {setToggle}
                />
            )
        })
            
        }
        {(!cartItems || (cartItems && cartItems.length == 0)) && (
            <div className="empty_cart">
                <div className="txt-font">Your Shopping Cart is Empty</div>
                <div className="txt-font1">Build your custom suits within few minitues and get it delivered within 14 days !!</div>
                <button className="Butt1"
                    onClick={()=>{
                        navigate("/")
                    }}
                >Shop Now</button>
            </div>
        )

        }

    </div>);
}

export default Cart;